import React from 'react'

import { BottomPromptView, PromptView } from '../../types'
import { FlowViewType } from '../../appConstants'
import BottomPrompt from './BottomPrompt'
import Prompt from './Prompt'

export type Props = {
  promptView: BottomPromptView | PromptView | null
  onClose: () => void
  onActionButtonClick: (actionId?: string) => void
}

const FlowPrompt = ({ promptView, onClose, onActionButtonClick }: Props) => {
  switch (promptView?.type) {
    case FlowViewType.BottomPrompt:
      return (
        <BottomPrompt
          promptView={promptView}
          onClose={onClose}
          onActionButtonClick={onActionButtonClick}
        />
      )
    case FlowViewType.Prompt:
      return (
        <Prompt
          promptView={promptView}
          onClose={onClose}
          onActionButtonClick={onActionButtonClick}
        />
      )
    case undefined:
    default:
      return null
  }
}

export default FlowPrompt
