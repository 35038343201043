import React from 'react'

import { Group, Cell, HStack, VStack, Icon, Text } from '@revolut/ui-kit'
import { BottomPromptListItem } from 'types'

import { HtmlStringComponent } from '../Controls/common'
import { DEFAULT_LIST_ICON } from '../../appConstants'

type Props = {
  items: BottomPromptListItem[]
}

const LegacyBottomPromptItems = ({ items }: Props) => {
  return (
    <Group>
      {items.map(({ textHtml, icon }, idx) => (
        <Cell
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
        >
          <HStack space="s-16">
            <Icon size={24} name={icon?.name ?? DEFAULT_LIST_ICON} />
            <VStack>
              <Text variant="primary">
                <HtmlStringComponent htmlString={textHtml} />
              </Text>
            </VStack>
          </HStack>
        </Cell>
      ))}
    </Group>
  )
}

export default LegacyBottomPromptItems
