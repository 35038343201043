import React, { FC } from 'react'
import { Avatar, Checkbox, Image, Item } from '@revolut/ui-kit'
import { Entity } from '../../../../types'

const ASSET_IMAGE_SIZE_PX = 80

type Props = {
  entity: Entity
  selected?: boolean
  disabled?: boolean
  onClick?(entityId: string): void
  onSelect?(entityId: string, selected: boolean): void
}

export const EntityItem: FC<Props> = ({
  entity,
  disabled,
  selected,
  onClick,
  onSelect,
}) => {
  const entityTitleId = `entity-${entity.id}-title`

  const entityDescriptionId = entity.description
    ? `entity-${entity.description}-description`
    : undefined

  return (
    <Item onClick={() => onClick?.(entity.id)} use={onClick ? 'button' : undefined}>
      {onSelect && (
        <Item.Prefix>
          <Checkbox
            onClick={e => e.stopPropagation()}
            checked={selected}
            disabled={disabled}
            onChange={event => onSelect(entity.id, event.target.checked)}
            aria-labelledby={entityTitleId}
            aria-describedby={entityDescriptionId}
          />
        </Item.Prefix>
      )}
      {(entity.assetUrl || entity.icon?.name) && (
        <Item.Avatar>
          {entity.assetUrl && (
            <Image
              size={ASSET_IMAGE_SIZE_PX}
              src={entity.assetUrl}
              borderRadius="r12"
              scale="cover"
            />
          )}
          {!entity.assetUrl && entity.icon?.name && <Avatar useIcon={entity.icon.name} />}
        </Item.Avatar>
      )}
      <Item.Content>
        <Item.Title id={entityTitleId}>{entity.title}</Item.Title>
        {!!entity.description && (
          <Item.Description id={entityDescriptionId}>
            {entity.description}
          </Item.Description>
        )}
      </Item.Content>
      {entity.secondaryDescription && (
        <Item.Side>
          <Item.Value>{entity.secondaryDescription}</Item.Value>
        </Item.Side>
      )}
    </Item>
  )
}
