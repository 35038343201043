import {
  IconCode,
  mapMerchantIconCodeToCategoryIcon,
  mapTransactionTypeIconCodeToIconUrl,
  MerchantIconCode,
} from '../../../../../appConstants'

const toCategoryIconUrl = (icon: string) =>
  `https://assets.revolut.com/assets/categories/${icon}@2x.png`

export const getTransactionIconUrl = (iconUrl?: string, icon?: IconCode): string => {
  if (iconUrl) return iconUrl

  const merchantIcon = icon && mapMerchantIconCodeToCategoryIcon[icon]
  if (merchantIcon) return toCategoryIconUrl(merchantIcon)

  const transactionIconUrl = icon && mapTransactionTypeIconCodeToIconUrl[icon]
  if (transactionIconUrl) return transactionIconUrl

  return toCategoryIconUrl(MerchantIconCode.General)
}
