import { useDateFormat, useDateTimeFormat } from '@revolut/ui-kit'
import { isToday } from 'date-fns'
import { TicketProgressStep } from 'types'

export const useStepDateFormat = (
  step: TicketProgressStep,
  options?: { isPassed?: boolean; isCurrent?: boolean; isLast?: boolean },
) => {
  const formatDate = useDateFormat()
  const formatDateTime = useDateTimeFormat()

  if (!step.date) return null

  const stepDateIsToday = isToday(step.date)
  const stepIsRed = step.stepColor === 'RED'

  if (stepDateIsToday && (options?.isPassed || options?.isCurrent))
    return formatDateTime(step.date)
  if (stepIsRed && options?.isLast) return formatDateTime(step.date)
  // TODO: Translate.
  if (!options?.isPassed) return `Est. ${formatDate(step.date)}`

  return formatDate(step.date)
}
