import { Avatar, IconName } from '@revolut/ui-kit'
import React from 'react'

const IconRegexp = /assets\/icons\/(\w+)\.svg/
export const SelectionOptionAvatar = ({ url }: { url: string }) => {
  const possibleIconName = IconRegexp.exec(url)?.[1] as IconName | undefined
  if (possibleIconName) {
    return <Avatar variant="brand" useIcon={possibleIconName} />
  }
  return <Avatar variant="brand" image={url} />
}
