import { ProgressStep, chain } from '@revolut/ui-kit'
import React from 'react'
import { TicketProgressStep } from 'types'
import {
  getWidgetProgressionStepIndicatorColor,
  getWidgetProgressionStepState,
} from './utils'
import { useStepDateFormat } from './useStepDateFormat'
import { WIDGET_PROGRESS_STEP_TEST_ID } from './constants'

type Props = {
  step: TicketProgressStep
  isPassed?: boolean
  isCurrent?: boolean
  isLast?: boolean
}

export const WidgetProgressionStep = ({ step, isPassed, isCurrent, isLast }: Props) => {
  const stepDate = useStepDateFormat(step, { isPassed, isCurrent, isLast })
  const hasDescription = !!(stepDate || step.description)

  return (
    <ProgressStep
      data-testid={WIDGET_PROGRESS_STEP_TEST_ID}
      state={getWidgetProgressionStepState(step.lineStyle)}
      indicatorColor={getWidgetProgressionStepIndicatorColor(step.stepColor)}
    >
      <ProgressStep.Title>{step.title}</ProgressStep.Title>
      {hasDescription && (
        <ProgressStep.Description>
          {chain(stepDate, step.description)}
        </ProgressStep.Description>
      )}
    </ProgressStep>
  )
}
