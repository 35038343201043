import { useQuery, UseQueryResult } from 'react-query'

import { TicketProgress } from '../../../types'
import { useApi } from '../../../providers'

export const useTicketProgress = (
  ticketId: string,
): UseQueryResult<TicketProgress, unknown> => {
  const api = useApi()

  return useQuery(['countriesConfig'], () => api.getTicketProgress?.(ticketId))
}
