import { useEffect, useState } from 'react'
import { useDebounce } from '@react-hook/debounce'

const DEBOUNCED_SEARCH_TIMEOUT = 300

export const useDebouncedSearch = <T>(
  items: T[],
  searchFn: (items: T[], searchString: string) => T[],
) => {
  const [filterInputValue, setFilterInputValue] = useState('')
  const [filteredItems, setFilteredItems] = useDebounce(items, DEBOUNCED_SEARCH_TIMEOUT)

  useEffect(() => {
    if (filterInputValue === '' && items.length > filteredItems.length) {
      setFilteredItems(items)
    }
  }, [filterInputValue, filteredItems.length, items, setFilteredItems])

  const debouncedSearch = (value: string) => {
    setFilterInputValue(value)
    setFilteredItems(searchFn(items, value))
  }

  return { filterInputValue, filteredItems, debouncedSearch }
}
