import React from 'react'
import { Avatar, Item, IconName } from '@revolut/ui-kit'

type Props = {
  text: string
  icon: string
  description?: string
  onClick: () => void
}
export const MEDIA_ICON_TESTID = 'action-button-media-icon-testid'

export const ActionCard: React.FC<Props> = ({ text, onClick, icon, description }) => {
  return (
    <Item use="button" onClick={onClick}>
      <Item.Avatar data-testid={MEDIA_ICON_TESTID}>
        <Avatar useIcon={icon as IconName} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title color="primary">{text}</Item.Title>
        {description?.length && <Item.Description>{description}</Item.Description>}
      </Item.Content>
    </Item>
  )
}

export default ActionCard
