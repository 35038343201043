import React from 'react'
import { Input, InputProps } from '@revolut/ui-kit'

type Props = Omit<InputProps, 'type' | 'onChange'> & {
  onChange: (value: string) => void
}
export const TextInputWithTrimOnBlur = ({ onBlur, onChange, ...restProps }: Props) => (
  <Input
    {...restProps}
    type="text"
    onChange={(event: React.FormEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.value)
    }}
    onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.value.trim())
      onBlur && onBlur(event)
    }}
  />
)
