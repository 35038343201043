import {
  Group,
  ProgressStepSkeleton,
  ProgressSteps,
  SubheaderSkeleton,
} from '@revolut/ui-kit'
import React from 'react'
import { WIDGET_PROGRESS_CELL_LOADING_TEST_ID } from './constants'

export const WidgetProgressionCellSkeleton = () => (
  <Group data-testid={WIDGET_PROGRESS_CELL_LOADING_TEST_ID} aria-busy>
    <SubheaderSkeleton />
    <ProgressSteps variant="vertical-compact">
      <ProgressStepSkeleton />
      <ProgressStepSkeleton />
      <ProgressStepSkeleton />
    </ProgressSteps>
  </Group>
)
