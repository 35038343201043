import React from 'react'
import { Popup as UIKitPopup, Header, Search, Box, HStack, Action } from '@revolut/ui-kit'
import { notReachable } from '../../../helpers'
import { NotAskedView } from '../SearchAddressViews/NotAskedView'
import { LoadingView } from '../SearchAddressViews/LoadingView'
import { useAddressValuesByPostcode } from './useAddressValuesByPostcode'
import { LoadedView } from './LoadedView'
import { NoResultView } from '../SearchAddressViews/NoResultView'
import { ErrorView } from '../SearchAddressViews/ErrorView'
import { AddressInputValue } from '../../../../types'
import { useDebouncedInput } from '../../common/hooks/useDebouncedInput'

type Props = {
  countryCode: string
  searchInputPlaceholder: string
  cancelButtonLabel: string
  emptySearchResultsTitle: string
  myAddressIsNotHereLabel: string
  noResultTitle: string
  errorTitle: string
  tryAgainLabel: string
  enterAddressManuallyLabel: string
  orLabel: string
  isOpen: boolean
  onSelectAddress: (addressValue: AddressInputValue) => void
  onEnterAddressManually: (postcode: string) => void
  onClose: () => void
}

export const Popup = ({
  countryCode,
  isOpen,
  searchInputPlaceholder,
  emptySearchResultsTitle,
  cancelButtonLabel,
  myAddressIsNotHereLabel,
  noResultTitle,
  errorTitle,
  tryAgainLabel,
  enterAddressManuallyLabel,
  orLabel,
  onSelectAddress,
  onEnterAddressManually,
  onClose,
}: Props) => {
  const {
    value: postcode,
    debouncedValue: debouncedPostCode,
    setValue: setPostCode,
  } = useDebouncedInput('')
  const addressValuesQuery = useAddressValuesByPostcode({
    countryCode,
    postcode: debouncedPostCode,
  })

  return (
    <UIKitPopup open={isOpen} variant="modal-view" onClose={onClose} shouldKeepMaxHeight>
      <Header variant="modal" hidden>
        <Header.Title>{searchInputPlaceholder}</Header.Title>
      </Header>
      <HStack space="s-16" pb="s-16">
        <Box width={1}>
          <Search
            autoFocus
            value={postcode}
            placeholder={searchInputPlaceholder}
            onChange={value => {
              setPostCode(value.toUpperCase())
            }}
          />
        </Box>
        <Action onClick={onClose}>{cancelButtonLabel}</Action>
      </HStack>
      {(() => {
        switch (addressValuesQuery.status) {
          case 'idle':
            return <NotAskedView title={emptySearchResultsTitle} />
          case 'loading':
            return <LoadingView />
          case 'success': {
            const { data: addressValues } = addressValuesQuery

            if (addressValues.length) {
              return (
                <LoadedView
                  addressValues={addressValues}
                  onCancel={() => onEnterAddressManually(postcode)}
                  myAddressIsNotHereLabel={myAddressIsNotHereLabel}
                  onSelect={onSelectAddress}
                />
              )
            }
            return (
              <NoResultView
                title={noResultTitle}
                enterAddressManuallyLabel={enterAddressManuallyLabel}
                onEnterAddressManually={() => onEnterAddressManually(postcode)}
              />
            )
          }
          case 'error':
            return (
              <ErrorView
                title={errorTitle}
                tryAgainLabel={tryAgainLabel}
                enterAddressManuallyLabel={enterAddressManuallyLabel}
                orLabel={orLabel}
                onTryAgain={addressValuesQuery.refetch}
                onEnterAddressManually={() => onEnterAddressManually(postcode)}
              />
            )
          /* istanbul ignore next */
          default:
            return notReachable(addressValuesQuery)
        }
      })()}
    </UIKitPopup>
  )
}
