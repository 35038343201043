import { createContext, useContext } from 'react'

type WidgetContextType = {
  isWidgetMode: boolean
}

export const WidgetContext = createContext<WidgetContextType>({
  isWidgetMode: false,
})

export const useIsWidgetMode = () => {
  const { isWidgetMode } = useContext(WidgetContext)
  return isWidgetMode
}
