import React from 'react'
import { Image, ImageProps } from '@revolut/ui-kit'
import { SingleSelectionItem } from 'types'

type AssetStyle = NonNullable<SingleSelectionItem['assetStyle']>

const ASSET_SIZE_PX_MAP: Record<AssetStyle, number> = {
  SQUARE: 60,
  ROUNDED_SQUARE: 80,
}

const getAssetStyleProps = (style: AssetStyle): ImageProps => {
  if (style === 'ROUNDED_SQUARE') {
    return { borderRadius: 'r12' }
  }
  return {}
}

interface Props {
  asset: string
  style?: AssetStyle
}

export const SelectionAsset = ({ asset, style = 'ROUNDED_SQUARE' }: Props) => {
  return (
    <Image
      size={ASSET_SIZE_PX_MAP[style]}
      src={asset}
      {...getAssetStyleProps(style)}
      scale="cover"
    />
  )
}
