import React from 'react'
import { AddressInputValue } from 'types'
import { TextInputWithTrimOnBlur } from '../../common/TextInputWithTrimOnBlur'
import { CountryStateSelect } from './CountryStateSelect'
import { AddressInputErrors } from '../helpers/validateAddressValue'

type Props = {
  value: AddressInputValue
  cityHint: string
  postcodeHint: string
  regionHint: string
  streetLine1Hint: string
  streetLine2Hint: string
  optionalHint: string
  errorTitle: string
  tryAgainLabel: string
  cancelButtonLabel: string
  errorMessages: { [key in keyof AddressInputErrors]: string | null }
  withPostcode: boolean
  onChange: (value: AddressInputValue) => void
}

export const AddressFields = ({
  value,
  cityHint,
  streetLine1Hint,
  streetLine2Hint,
  regionHint,
  postcodeHint,
  errorTitle,
  tryAgainLabel,
  cancelButtonLabel,
  optionalHint,
  errorMessages,
  withPostcode,
  onChange,
}: Props) => {
  return (
    <>
      <TextInputWithTrimOnBlur
        required
        autoFocus
        autoComplete="off"
        label={streetLine1Hint}
        value={value.streetLine1}
        message={errorMessages.streetLine1}
        aria-invalid={!!errorMessages.streetLine1}
        onChange={streetLine1 => {
          onChange({ ...value, streetLine1 })
        }}
      />
      <TextInputWithTrimOnBlur
        autoComplete="off"
        label={streetLine2Hint}
        value={value.streetLine2}
        message={errorMessages.streetLine2 || optionalHint}
        aria-invalid={!!errorMessages.streetLine2}
        onChange={streetLine2 => {
          onChange({ ...value, streetLine2 })
        }}
      />
      {withPostcode && (
        <TextInputWithTrimOnBlur
          required
          autoComplete="off"
          label={postcodeHint}
          value={value.postcode}
          message={errorMessages.postcode}
          aria-invalid={!!errorMessages.postcode}
          onChange={postcode => {
            onChange({ ...value, postcode })
          }}
        />
      )}
      {shouldShowCityInput(value.country) && (
        <TextInputWithTrimOnBlur
          required
          autoComplete="off"
          label={cityHint}
          value={value.city}
          message={errorMessages.city}
          aria-invalid={!!errorMessages.city}
          onChange={city => {
            onChange({ ...value, city })
          }}
        />
      )}
      {shouldShowRegionInput(value.country) && (
        <TextInputWithTrimOnBlur
          required
          autoComplete="off"
          label={regionHint}
          value={value.region}
          message={errorMessages.region}
          aria-invalid={!!errorMessages.region}
          onChange={region => {
            onChange({ ...value, region })
          }}
        />
      )}
      {shouldShowCountryStateSelect(value.country) && (
        <CountryStateSelect
          countryCode={value.country}
          errorTitle={errorTitle}
          tryAgainLabel={tryAgainLabel}
          cancelButtonLabel={cancelButtonLabel}
          value={value.region}
          label={regionHint}
          placeholder={regionHint}
          message={errorMessages.region}
          aria-invalid={!!errorMessages.region}
          onChange={region => onChange({ ...value, region })}
          onErrorClose={() =>
            onChange({
              country: 'GB',
              postcode: '',
              streetLine1: '',
              streetLine2: '',
              city: '',
              region: '',
            })
          }
        />
      )}
    </>
  )
}

const shouldShowCityInput = (countryCode: string) => {
  switch (countryCode) {
    case 'SG':
      return false
    default:
      return true
  }
}

const shouldShowRegionInput = (countryCode: string) => {
  switch (countryCode) {
    case 'MX':
      return true
    default:
      return false
  }
}

const shouldShowCountryStateSelect = (
  countryCode: string,
): countryCode is 'US' | 'AU' => {
  switch (countryCode) {
    case 'AU':
    case 'US':
      return true
    default:
      return false
  }
}
