import React, { useState } from 'react'
import { AddressInputValue } from '../../../../types'
import { SearchInput } from '../SearchInput'
import { Popup } from './Popup'

type Props = {
  label: string
  value: string
  countryCode: string
  cancelButtonLabel: string
  searchInputPlaceholder: string
  emptySearchResultsTitle: string
  myAddressIsNotHereLabel: string
  noResultTitle: string
  errorTitle: string
  tryAgainLabel: string
  enterAddressManuallyLabel: string
  orLabel: string
  onSelect: (value: AddressInputValue) => void
  onEnterAddressManually: (postcode: string) => void
}

export const Input = ({
  label,
  cancelButtonLabel,
  value,
  searchInputPlaceholder,
  emptySearchResultsTitle,
  countryCode,
  myAddressIsNotHereLabel,
  noResultTitle,
  errorTitle,
  tryAgainLabel,
  enterAddressManuallyLabel,
  orLabel,
  onSelect,
  onEnterAddressManually,
}: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  return (
    <>
      <SearchInput label={label} value={value} onClick={() => setIsPopupOpen(true)} />
      <Popup
        isOpen={isPopupOpen}
        countryCode={countryCode}
        cancelButtonLabel={cancelButtonLabel}
        searchInputPlaceholder={searchInputPlaceholder}
        emptySearchResultsTitle={emptySearchResultsTitle}
        myAddressIsNotHereLabel={myAddressIsNotHereLabel}
        noResultTitle={noResultTitle}
        errorTitle={errorTitle}
        tryAgainLabel={tryAgainLabel}
        enterAddressManuallyLabel={enterAddressManuallyLabel}
        orLabel={orLabel}
        onClose={() => {
          setIsPopupOpen(false)
        }}
        onEnterAddressManually={postcode => {
          setIsPopupOpen(false)
          onEnterAddressManually(postcode)
        }}
        onSelectAddress={value => {
          setIsPopupOpen(false)
          onSelect(value)
        }}
      />
    </>
  )
}
