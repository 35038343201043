import { Box, Text, Token } from '@revolut/ui-kit'
import styled, { keyframes } from 'styled-components'

export const BoxStyled = styled(Box)`
  flex-shrink: 0;
  position: sticky;
  pointer-events: none;
  padding-top: ${Token.space.s16};
  padding-bottom: ${Token.space.s24};
  bottom: 0;
  width: 100%;
`

export const InnerBoxWrapperStyled = styled(Box)`
  margin: 0 auto;
  pointer-events: all;

  @media not ${Token.media.md} {
    max-width: 100%;
  }
`

const animationTextAppear = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const TextStyled = styled(Text)`
  display: block;
  text-align: center;
  opacity: 0;
  max-width: 400px;
  font-size: ${Token.fontSize.secondary};
  line-height: ${Token.lineHeight.secondary};
  color: ${Token.color.grey50};
  margin: 0 auto ${Token.space.s2};
  animation: ${animationTextAppear} 0.3s ease 0.6s;
  animation-fill-mode: forwards;
`
