import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useInfiniteQuery } from 'react-query'
import { format } from 'date-fns'

import { Transaction } from '../../../types'
import { useApi, useFlowId } from '../../../providers'

export const useTransactions = (
  itemId: string,
  searchKeyword: string,
  preselectedTransactionIds: string[],
) => {
  const api = useApi()
  const flowId = useFlowId()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const transactionId = queryParams.get('transactionId')

  const {
    data,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['transactions', itemId, searchKeyword, preselectedTransactionIds],
    ({ pageParam }) =>
      api.getTransactions({
        flowId,
        itemId,
        createdBefore: pageParam ?? Date.now(),
        searchKeyword,
        ...(transactionId ? { transactionId } : {}),
        ...(preselectedTransactionIds.length
          ? { excludeTransactionIds: preselectedTransactionIds }
          : {}),
      }),
    {
      staleTime: Infinity,
      getNextPageParam: ({ last, createdSince }) => (last ? undefined : createdSince),
    },
  )

  const transactions = useMemo(
    () => data?.pages?.map(page => page?.transactions)?.flat<Transaction[][]>() ?? [],
    [data?.pages],
  )

  const transactionsGroupedByDate = useMemo(
    () =>
      transactions.reduce<Record<string, Transaction[]>>((groups, transaction) => {
        const date = format(transaction.createdDate, 'd MMM yyyy')
        groups[date] = [...(groups[date] ?? []), transaction]
        return groups
      }, {}),
    [transactions],
  )

  return {
    transactions,
    transactionsGroupedByDate,
    fetchNextTransactionsPage: fetchNextPage,
    isNextTransactionsPageFetching: isFetchingNextPage,
    isTransactionsFetching: isFetching,
    hasNextPage,
  }
}
