/**
 * Why we should use this component?
 * revolut/ui-kit doesn't include default browser's select in InputSelect component.
 * On mobile devices we must use default options list behavior for better user experience.
 * This component could be useless when we start to use other UI library.
 */

import React, { FC, useLayoutEffect, useMemo } from 'react'
import { SelectInput } from '@revolut/ui-kit'
import { Box } from '@revolut/ui-kit'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

const StyledSelect = styled.select`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`

const RelativeBox = styled(Box)`
  position: relative;
`

type Props = {
  options: { value: string; label: string }[]
  value?: string
  disabled?: boolean
  placeholder?: string
  onChange: (value: string) => void
}

const HiddenSelect: FC<Props> = ({ options, onChange, value, disabled }) => {
  const optionsList = options.map(item => (
    <option key={item.value} value={item.value}>
      {item.label}
    </option>
  ))

  return (
    <StyledSelect
      onChange={event => onChange(event.target.value)}
      value={value}
      disabled={disabled}
    >
      {optionsList}
    </StyledSelect>
  )
}

const AdaptiveSelect: FC<Props> = ({
  options,
  value,
  onChange,
  disabled,
  placeholder,
}) => {
  const optionsWithKeys = useMemo(
    () => options.map(option => ({ ...option, key: option.value })),
    [options],
  )

  useLayoutEffect(() => {
    if (!value && options.length) {
      onChange(options[0].value)
    }
  }, [onChange, options, value])

  const valueWithFallback = value || ''

  return (
    <RelativeBox>
      <SelectInput
        value={valueWithFallback}
        onChange={selectedValue => {
          if (selectedValue !== null) {
            onChange(selectedValue)
          }
        }}
        options={optionsWithKeys}
        label={placeholder}
        disabled={disabled}
      />
      {isMobile && (
        <HiddenSelect
          options={options}
          onChange={onChange}
          value={valueWithFallback}
          disabled={disabled}
        />
      )}
    </RelativeBox>
  )
}

export default AdaptiveSelect
