import React, { FC, MouseEventHandler } from 'react'
import { ActionButton } from '@revolut/ui-kit'

type Props = {
  disabled: boolean
  buttonTitle: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const BUTTON_TAKE_PICTURE_TESTID = 'button-take-picture-testid'

const TakePictureButton: FC<Props> = ({ onClick, disabled, buttonTitle }) => (
  <ActionButton
    my="s-16"
    mx="s-8"
    data-testid={BUTTON_TAKE_PICTURE_TESTID}
    disabled={disabled}
    useIcon="Camera"
    variant="accent"
    onClick={onClick}
  >
    {buttonTitle || 'Take picture'}
  </ActionButton>
)

export default TakePictureButton
