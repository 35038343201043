import React, { FC, useEffect, useState } from 'react'

import { useMoveToTheNextView } from '../../../providers/FlowContext'
import { CountryInputItem } from '../../../types'
import { notReachable } from '../../helpers'
import { FALLBACK_COUNTRIES } from '../common/constants/fallbackCountries'
import CountrySelectionList from '../common/CountrySelectionList'
import { CountrySelectorInput } from '../common/CountrySelectorInput'

type Props = Pick<
  CountryInputItem,
  'countries' | 'hint' | 'searchHint' | 'value' | 'style'
> & {
  disabled: boolean
  required: boolean
  singleDynamicItemOnView: boolean
  changeValue: (value: string) => void
}

const CountryInput: FC<Props> = ({
  countries = FALLBACK_COUNTRIES,
  hint = 'Country',
  searchHint = 'Search country',
  singleDynamicItemOnView,
  disabled,
  required,
  style,
  value,
  changeValue,
}) => {
  const [isMoveToTheNextViewPending, setIsMoveToTheNextViewPending] = useState(false)
  const moveToTheNextView = useMoveToTheNextView()

  useEffect(() => {
    // Do move after the state got updated.
    if (isMoveToTheNextViewPending) {
      moveToTheNextView()
      setIsMoveToTheNextViewPending(false)
    }
  }, [isMoveToTheNextViewPending, moveToTheNextView, required])

  switch (style) {
    case undefined:
    case 'DROPDOWN':
      return (
        <CountrySelectorInput
          label={hint}
          disabled={disabled}
          pending={false}
          value={value?.value || null}
          countrySelectorPopupTitle={hint}
          countrySelectorPopupSearchPlaceholder={searchHint}
          countrySelectorPopupNoResultHint=""
          countries={countries}
          onChange={changeValue}
        />
      )
    case 'LIST':
      return (
        <CountrySelectionList
          searchHint={searchHint}
          countries={countries}
          disabled={disabled}
          onSelect={countryCode => {
            changeValue(countryCode)
            if (singleDynamicItemOnView) {
              setIsMoveToTheNextViewPending(true)
            }
          }}
        />
      )
    default:
      return notReachable(style)
  }
}

export default CountryInput
