import React from 'react'
import { Input, InputProps, IconButton } from '@revolut/ui-kit'

type Props = Pick<InputProps, 'pending' | 'disabled' | 'value'> & {
  label: string
  onClick: VoidFunction
}

export const SearchInput = ({ pending, disabled, label, value, onClick }: Props) => {
  return (
    <Input
      autoFocus
      autoComplete="off"
      pending={pending}
      disabled={disabled}
      type="button"
      label={label}
      value={value || ''}
      renderAction={() => (
        <IconButton
          aria-label={label}
          disabled={pending || disabled}
          useIcon="Search"
          color="grey-tone-50"
          onClick={onClick}
        />
      )}
      onClick={onClick}
    />
  )
}
