import React from 'react'
import { Group, Item, Image } from '@revolut/ui-kit'

export const NotAskedView = ({ title }: { title: string }) => (
  <Group>
    <Item>
      <Item.Avatar>
        <Image src="https://assets.revolut.com/assets/3d-images/3D086@2x.png" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title color="grey-tone-50">{title}</Item.Title>
      </Item.Content>
    </Item>
  </Group>
)
