import React, { FC, useEffect, useState } from 'react'
import { Cell, Group, Radio, RadioGroup, VStack } from '@revolut/ui-kit'

import { BooleanValue, YesNoInputItem } from '../../../types'
import { useMoveToTheNextView } from '../../../providers/FlowContext'

type Props = Pick<YesNoInputItem, 'yesTitle' | 'noTitle' | 'value'> & {
  yesTitle: string
  noTitle: string
  required: boolean
  disabled: boolean
  hasCustomActions: boolean
  singleDynamicItemOnView: boolean
  changeValue: (value: BooleanValue['value']) => void
}

export enum YesNoOption {
  Yes = 'yes',
  No = 'no',
}

const YesNoInput: FC<Props> = ({
  yesTitle,
  noTitle,
  required,
  disabled,
  hasCustomActions,
  value,
  singleDynamicItemOnView,
  changeValue,
}) => {
  const [isMoveToTheNextViewPending, setIsMoveToTheNextViewPending] = useState(false)
  const moveToTheNextView = useMoveToTheNextView()

  useEffect(() => {
    // Do move after the state got updated.
    if (isMoveToTheNextViewPending) {
      moveToTheNextView()
      setIsMoveToTheNextViewPending(false)
    }
  }, [hasCustomActions, isMoveToTheNextViewPending, moveToTheNextView, required])

  return hasCustomActions || !singleDynamicItemOnView ? (
    <Group>
      <RadioGroup
        value={value?.value ?? null}
        onChange={val => {
          if (val === null) return
          changeValue(val)
        }}
      >
        {({ getInputProps }) => (
          <>
            <Cell use="label">
              <Radio {...getInputProps({ value: true })}>
                {yesTitle || YesNoOption.Yes}
              </Radio>
            </Cell>
            <Cell use="label">
              <Radio {...getInputProps({ value: false })}>
                {noTitle || YesNoOption.No}
              </Radio>
            </Cell>
          </>
        )}
      </RadioGroup>
    </Group>
  ) : (
    <VStack space="s-8">
      <Cell
        use="button"
        variant="disclosure"
        disabled={disabled}
        onClick={() => {
          changeValue(true)
          setIsMoveToTheNextViewPending(true)
        }}
      >
        {yesTitle || YesNoOption.Yes}
      </Cell>
      <Cell
        use="button"
        variant="disclosure"
        disabled={disabled}
        onClick={() => {
          changeValue(false)
          setIsMoveToTheNextViewPending(true)
        }}
      >
        {noTitle || YesNoOption.No}
      </Cell>
    </VStack>
  )
}

export default YesNoInput
