import { Popup as UIKitPopup, Header, Search, Box, HStack, Action } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { notReachable } from '../../../helpers'
import { NotAskedView } from '../SearchAddressViews/NotAskedView'
import { LoadingView } from '../SearchAddressViews/LoadingView'
import { useAddressSuggestions } from './useAddressSuggestions'
import { LoadedView } from './LoadedView'
import { NoResultView } from '../SearchAddressViews/NoResultView'
import { ErrorView } from '../SearchAddressViews/ErrorView'
import { useDebouncedInput } from '../../common/hooks/useDebouncedInput'

const RESPONSE_DATA_LIMIT = 7

type Props = {
  countryCode: string
  searchInputPlaceholder: string
  cancelButtonLabel: string
  emptySearchResultsTitle: string
  myAddressIsNotHereLabel: string
  noResultTitle: string
  errorTitle: string
  tryAgainLabel: string
  enterAddressManuallyLabel: string
  orLabel: string
  isOpen: boolean
  onSelectAddress: (addressId: string) => void
  onClose: () => void
  onEnterAddressManually: () => void
}

export const Popup = ({
  countryCode,
  isOpen,
  searchInputPlaceholder,
  emptySearchResultsTitle,
  cancelButtonLabel,
  myAddressIsNotHereLabel,
  noResultTitle,
  errorTitle,
  tryAgainLabel,
  enterAddressManuallyLabel,
  orLabel,
  onSelectAddress,
  onClose,
  onEnterAddressManually,
}: Props) => {
  const {
    value: searchString,
    debouncedValue: debouncedSearchString,
    setValue: setSearchString,
  } = useDebouncedInput('')
  const [parentAddressId, setParentAddressId] = useState<string | null>(null)
  const addressSuggestionsQuery = useAddressSuggestions({
    countryCode,
    limit: RESPONSE_DATA_LIMIT,
    text: debouncedSearchString,
    parent: parentAddressId,
  })

  return (
    <UIKitPopup open={isOpen} variant="modal-view" onClose={onClose} shouldKeepMaxHeight>
      <Header variant="modal" hidden>
        <Header.Title>{searchInputPlaceholder}</Header.Title>
      </Header>
      <HStack space="s-16" pb="s-16">
        <Box width={1}>
          <Search
            autoFocus
            value={searchString}
            placeholder={searchInputPlaceholder}
            onChange={value => {
              setSearchString(value)
            }}
          />
        </Box>
        <Action onClick={onClose}>{cancelButtonLabel}</Action>
      </HStack>
      {(() => {
        switch (addressSuggestionsQuery.status) {
          case 'idle':
            return <NotAskedView title={emptySearchResultsTitle} />
          case 'loading':
            return <LoadingView />
          case 'success': {
            const { data: suggestions } = addressSuggestionsQuery

            if (suggestions.length) {
              return (
                <LoadedView
                  suggestions={suggestions}
                  onCancel={onEnterAddressManually}
                  myAddressIsNotHereLabel={myAddressIsNotHereLabel}
                  onSelect={addressSuggestion => {
                    switch (addressSuggestion.type) {
                      case 'container':
                        setParentAddressId(addressSuggestion.id)
                        break
                      case 'final':
                        setParentAddressId(null)
                        setSearchString('')
                        onSelectAddress(addressSuggestion.id)
                        break
                      /* istanbul ignore next */
                      default:
                        notReachable(addressSuggestion)
                    }
                  }}
                />
              )
            }
            return (
              <NoResultView
                title={noResultTitle}
                enterAddressManuallyLabel={enterAddressManuallyLabel}
                onEnterAddressManually={onEnterAddressManually}
              />
            )
          }
          case 'error':
            return (
              <ErrorView
                title={errorTitle}
                tryAgainLabel={tryAgainLabel}
                enterAddressManuallyLabel={enterAddressManuallyLabel}
                orLabel={orLabel}
                onTryAgain={addressSuggestionsQuery.refetch}
                onEnterAddressManually={onEnterAddressManually}
              />
            )
          /* istanbul ignore next */
          default:
            return notReachable(addressSuggestionsQuery)
        }
      })()}
    </UIKitPopup>
  )
}
