import React from 'react'

import { Group, ProgressSteps, Subheader } from '@revolut/ui-kit'
import { WidgetProgressionCellSkeleton } from './WidgetProgressionCellSkeleton'
import { useTicketProgress } from './useTicketProgress'
import { WidgetProgressionStep } from './WidgetProgressionStep'
import {
  WIDGET_PROGRESS_CELL_ERROR_TEST_ID,
  WIDGET_PROGRESS_CELL_TEST_ID,
} from './constants'
import { ItemError } from '../common'

type Props = {
  ticketId: string
}

const WidgetProgressionCell = ({ ticketId }: Props) => {
  const { data: ticketProgression, isLoading, isError, refetch } = useTicketProgress(
    ticketId,
  )

  if (isLoading) return <WidgetProgressionCellSkeleton />

  if (isError || (!isLoading && !ticketProgression))
    return (
      <ItemError onRetry={refetch} data-testid={WIDGET_PROGRESS_CELL_ERROR_TEST_ID} />
    )

  return (
    <Group data-testid={WIDGET_PROGRESS_CELL_TEST_ID}>
      <Subheader>{ticketProgression.headerTitle}</Subheader>
      <ProgressSteps variant="vertical-compact">
        {ticketProgression?.steps.map((step, idx) => (
          <WidgetProgressionStep
            key={step.date}
            step={step}
            isPassed={idx < ticketProgression.currentStepIndex}
            isCurrent={idx === ticketProgression.currentStepIndex}
            isLast={idx === ticketProgression.steps.length - 1}
          />
        ))}
      </ProgressSteps>
    </Group>
  )
}

export default WidgetProgressionCell
