import { format, isToday } from 'date-fns'
import { MoneyValue } from 'types'
import { FALLBACK_CURRENCIES } from '../constants/fallbackCurrencies'
import { moneyValueToReadableValue } from '../../../helpers'

export const formatDate = (timestamp: number) => {
  const date = new Date(timestamp)
  return isToday(date) ? format(date, 'HH:mm') : format(date, 'dd/MM/yyyy HH:mm')
}

export const getCurrencyLabel = (moneyValue: MoneyValue) => {
  const currencyDetails = FALLBACK_CURRENCIES.find(
    ({ code }) => code === moneyValue.currency,
  )
  if (!currencyDetails) return ''
  const readableValue = moneyValueToReadableValue(moneyValue, currencyDetails)
  return `${readableValue} ${currencyDetails?.symbol ?? ''}`
}
