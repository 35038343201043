import { AddressCountryConfig } from 'types'

export const getCountryAvailableAddressSearchMethod = (
  countryCode: string,
  addressCountrySearchConfig: AddressCountryConfig,
): 'searchable_by_substring' | 'searchable_by_postcode' | 'manual_input' => {
  if (addressCountrySearchConfig.countriesSearchableBySubString.includes(countryCode)) {
    return 'searchable_by_substring'
  }

  if (addressCountrySearchConfig.countriesSearchableByPostCode.includes(countryCode)) {
    return 'searchable_by_postcode'
  }

  return 'manual_input'
}
