export const FALLBACK_REGIONS = [
  { code: 1, countryCode: 'PR', description: 'Puerto Rico' },
  { code: 970, countryCode: 'PS', description: 'Palestinian Territories' },
  { code: 351, countryCode: 'PT', description: 'Portugal' },
  { code: 680, countryCode: 'PW', description: 'Palau' },
  { code: 595, countryCode: 'PY', description: 'Paraguay' },
  { code: 974, countryCode: 'QA', description: 'Qatar' },
  { code: 247, countryCode: 'AC', description: 'Ascension Island' },
  { code: 376, countryCode: 'AD', description: 'Andorra' },
  { code: 971, countryCode: 'AE', description: 'United Arab Emirates' },
  { code: 93, countryCode: 'AF', description: 'Afghanistan' },
  { code: 1, countryCode: 'AG', description: 'Antigua & Barbuda' },
  { code: 1, countryCode: 'AI', description: 'Anguilla' },
  { code: 355, countryCode: 'AL', description: 'Albania' },
  { code: 374, countryCode: 'AM', description: 'Armenia' },
  { code: 244, countryCode: 'AO', description: 'Angola' },
  { code: 54, countryCode: 'AR', description: 'Argentina' },
  { code: 1, countryCode: 'AS', description: 'American Samoa' },
  { code: 43, countryCode: 'AT', description: 'Austria' },
  { code: 262, countryCode: 'RE', description: 'Réunion' },
  { code: 61, countryCode: 'AU', description: 'Australia' },
  { code: 297, countryCode: 'AW', description: 'Aruba' },
  { code: 358, countryCode: 'AX', description: 'Åland Islands' },
  { code: 994, countryCode: 'AZ', description: 'Azerbaijan' },
  { code: 40, countryCode: 'RO', description: 'Romania' },
  { code: 387, countryCode: 'BA', description: 'Bosnia & Herzegovina' },
  { code: 1, countryCode: 'BB', description: 'Barbados' },
  { code: 381, countryCode: 'RS', description: 'Serbia' },
  { code: 880, countryCode: 'BD', description: 'Bangladesh' },
  { code: 7, countryCode: 'RU', description: 'Russia' },
  { code: 32, countryCode: 'BE', description: 'Belgium' },
  { code: 226, countryCode: 'BF', description: 'Burkina Faso' },
  { code: 250, countryCode: 'RW', description: 'Rwanda' },
  { code: 359, countryCode: 'BG', description: 'Bulgaria' },
  { code: 973, countryCode: 'BH', description: 'Bahrain' },
  { code: 257, countryCode: 'BI', description: 'Burundi' },
  { code: 229, countryCode: 'BJ', description: 'Benin' },
  { code: 590, countryCode: 'BL', description: 'St Barthélemy' },
  { code: 1, countryCode: 'BM', description: 'Bermuda' },
  { code: 673, countryCode: 'BN', description: 'Brunei' },
  { code: 591, countryCode: 'BO', description: 'Bolivia' },
  { code: 966, countryCode: 'SA', description: 'Saudi Arabia' },
  { code: 599, countryCode: 'BQ', description: 'Caribbean Netherlands' },
  { code: 677, countryCode: 'SB', description: 'Solomon Islands' },
  { code: 55, countryCode: 'BR', description: 'Brazil' },
  { code: 248, countryCode: 'SC', description: 'Seychelles' },
  { code: 1, countryCode: 'BS', description: 'Bahamas' },
  { code: 249, countryCode: 'SD', description: 'Sudan' },
  { code: 46, countryCode: 'SE', description: 'Sweden' },
  { code: 975, countryCode: 'BT', description: 'Bhutan' },
  { code: 65, countryCode: 'SG', description: 'Singapore' },
  { code: 267, countryCode: 'BW', description: 'Botswana' },
  { code: 290, countryCode: 'SH', description: 'St Helena' },
  { code: 386, countryCode: 'SI', description: 'Slovenia' },
  { code: 47, countryCode: 'SJ', description: 'Svalbard & Jan Mayen' },
  { code: 375, countryCode: 'BY', description: 'Belarus' },
  { code: 421, countryCode: 'SK', description: 'Slovakia' },
  { code: 501, countryCode: 'BZ', description: 'Belize' },
  { code: 232, countryCode: 'SL', description: 'Sierra Leone' },
  { code: 378, countryCode: 'SM', description: 'San Marino' },
  { code: 221, countryCode: 'SN', description: 'Senegal' },
  { code: 252, countryCode: 'SO', description: 'Somalia' },
  { code: 1, countryCode: 'CA', description: 'Canada' },
  { code: 597, countryCode: 'SR', description: 'Suriname' },
  { code: 61, countryCode: 'CC', description: 'Cocos (Keeling) Islands' },
  { code: 211, countryCode: 'SS', description: 'South Sudan' },
  { code: 239, countryCode: 'ST', description: 'São Tomé & Príncipe' },
  { code: 243, countryCode: 'CD', description: 'Congo - Kinshasa' },
  { code: 236, countryCode: 'CF', description: 'Central African Republic' },
  { code: 503, countryCode: 'SV', description: 'El Salvador' },
  { code: 242, countryCode: 'CG', description: 'Congo - Brazzaville' },
  { code: 1, countryCode: 'SX', description: 'Sint Maarten' },
  { code: 41, countryCode: 'CH', description: 'Switzerland' },
  { code: 225, countryCode: 'CI', description: 'Côte d’Ivoire' },
  { code: 963, countryCode: 'SY', description: 'Syria' },
  { code: 268, countryCode: 'SZ', description: 'Swaziland' },
  { code: 682, countryCode: 'CK', description: 'Cook Islands' },
  { code: 56, countryCode: 'CL', description: 'Chile' },
  { code: 237, countryCode: 'CM', description: 'Cameroon' },
  { code: 86, countryCode: 'CN', description: 'China' },
  { code: 57, countryCode: 'CO', description: 'Colombia' },
  { code: 290, countryCode: 'TA', description: 'Tristan da Cunha' },
  { code: 1, countryCode: 'TC', description: 'Turks & Caicos Islands' },
  { code: 506, countryCode: 'CR', description: 'Costa Rica' },
  { code: 235, countryCode: 'TD', description: 'Chad' },
  { code: 53, countryCode: 'CU', description: 'Cuba' },
  { code: 228, countryCode: 'TG', description: 'Togo' },
  { code: 238, countryCode: 'CV', description: 'Cape Verde' },
  { code: 66, countryCode: 'TH', description: 'Thailand' },
  { code: 599, countryCode: 'CW', description: 'Curaçao' },
  { code: 61, countryCode: 'CX', description: 'Christmas Island' },
  { code: 357, countryCode: 'CY', description: 'Cyprus' },
  { code: 992, countryCode: 'TJ', description: 'Tajikistan' },
  { code: 690, countryCode: 'TK', description: 'Tokelau' },
  { code: 420, countryCode: 'CZ', description: 'Czechia' },
  { code: 670, countryCode: 'TL', description: 'Timor-Leste' },
  { code: 993, countryCode: 'TM', description: 'Turkmenistan' },
  { code: 216, countryCode: 'TN', description: 'Tunisia' },
  { code: 676, countryCode: 'TO', description: 'Tonga' },
  { code: 90, countryCode: 'TR', description: 'Turkey' },
  { code: 1, countryCode: 'TT', description: 'Trinidad & Tobago' },
  { code: 49, countryCode: 'DE', description: 'Germany' },
  { code: 688, countryCode: 'TV', description: 'Tuvalu' },
  { code: 886, countryCode: 'TW', description: 'Taiwan' },
  { code: 253, countryCode: 'DJ', description: 'Djibouti' },
  { code: 255, countryCode: 'TZ', description: 'Tanzania' },
  { code: 45, countryCode: 'DK', description: 'Denmark' },
  { code: 1, countryCode: 'DM', description: 'Dominica' },
  { code: 1, countryCode: 'DO', description: 'Dominican Republic' },
  { code: 380, countryCode: 'UA', description: 'Ukraine' },
  { code: 256, countryCode: 'UG', description: 'Uganda' },
  { code: 213, countryCode: 'DZ', description: 'Algeria' },
  { code: 1, countryCode: 'US', description: 'United States' },
  { code: 593, countryCode: 'EC', description: 'Ecuador' },
  { code: 372, countryCode: 'EE', description: 'Estonia' },
  { code: 20, countryCode: 'EG', description: 'Egypt' },
  { code: 212, countryCode: 'EH', description: 'Western Sahara' },
  { code: 598, countryCode: 'UY', description: 'Uruguay' },
  { code: 998, countryCode: 'UZ', description: 'Uzbekistan' },
  { code: 39, countryCode: 'VA', description: 'Vatican City' },
  { code: 1, countryCode: 'VC', description: 'St Vincent & Grenadines' },
  { code: 291, countryCode: 'ER', description: 'Eritrea' },
  { code: 34, countryCode: 'ES', description: 'Spain' },
  { code: 58, countryCode: 'VE', description: 'Venezuela' },
  { code: 251, countryCode: 'ET', description: 'Ethiopia' },
  { code: 1, countryCode: 'VG', description: 'British Virgin Islands' },
  { code: 1, countryCode: 'VI', description: 'US Virgin Islands' },
  { code: 84, countryCode: 'VN', description: 'Vietnam' },
  { code: 678, countryCode: 'VU', description: 'Vanuatu' },
  { code: 358, countryCode: 'FI', description: 'Finland' },
  { code: 679, countryCode: 'FJ', description: 'Fiji' },
  { code: 500, countryCode: 'FK', description: 'Falkland Islands' },
  { code: 691, countryCode: 'FM', description: 'Micronesia' },
  { code: 298, countryCode: 'FO', description: 'Faroe Islands' },
  { code: 33, countryCode: 'FR', description: 'France' },
  { code: 681, countryCode: 'WF', description: 'Wallis & Futuna' },
  { code: 241, countryCode: 'GA', description: 'Gabon' },
  { code: 44, countryCode: 'GB', description: 'United Kingdom' },
  { code: 685, countryCode: 'WS', description: 'Samoa' },
  { code: 1, countryCode: 'GD', description: 'Grenada' },
  { code: 995, countryCode: 'GE', description: 'Georgia' },
  { code: 594, countryCode: 'GF', description: 'French Guiana' },
  { code: 44, countryCode: 'GG', description: 'Guernsey' },
  { code: 233, countryCode: 'GH', description: 'Ghana' },
  { code: 350, countryCode: 'GI', description: 'Gibraltar' },
  { code: 299, countryCode: 'GL', description: 'Greenland' },
  { code: 220, countryCode: 'GM', description: 'Gambia' },
  { code: 224, countryCode: 'GN', description: 'Guinea' },
  { code: 590, countryCode: 'GP', description: 'Guadeloupe' },
  { code: 240, countryCode: 'GQ', description: 'Equatorial Guinea' },
  { code: 30, countryCode: 'GR', description: 'Greece' },
  { code: 502, countryCode: 'GT', description: 'Guatemala' },
  { code: 1, countryCode: 'GU', description: 'Guam' },
  { code: 245, countryCode: 'GW', description: 'Guinea-Bissau' },
  { code: 592, countryCode: 'GY', description: 'Guyana' },
  { code: 383, countryCode: 'XK', description: 'Kosovo' },
  { code: 852, countryCode: 'HK', description: 'Hong Kong SAR China' },
  { code: 504, countryCode: 'HN', description: 'Honduras' },
  { code: 385, countryCode: 'HR', description: 'Croatia' },
  { code: 967, countryCode: 'YE', description: 'Yemen' },
  { code: 509, countryCode: 'HT', description: 'Haiti' },
  { code: 36, countryCode: 'HU', description: 'Hungary' },
  { code: 62, countryCode: 'ID', description: 'Indonesia' },
  { code: 262, countryCode: 'YT', description: 'Mayotte' },
  { code: 353, countryCode: 'IE', description: 'Ireland' },
  { code: 972, countryCode: 'IL', description: 'Israel' },
  { code: 44, countryCode: 'IM', description: 'Isle of Man' },
  { code: 91, countryCode: 'IN', description: 'India' },
  { code: 246, countryCode: 'IO', description: 'British Indian Ocean Territory' },
  { code: 27, countryCode: 'ZA', description: 'South Africa' },
  { code: 964, countryCode: 'IQ', description: 'Iraq' },
  { code: 98, countryCode: 'IR', description: 'Iran' },
  { code: 354, countryCode: 'IS', description: 'Iceland' },
  { code: 39, countryCode: 'IT', description: 'Italy' },
  { code: 260, countryCode: 'ZM', description: 'Zambia' },
  { code: 44, countryCode: 'JE', description: 'Jersey' },
  { code: 263, countryCode: 'ZW', description: 'Zimbabwe' },
  { code: 1, countryCode: 'JM', description: 'Jamaica' },
  { code: 962, countryCode: 'JO', description: 'Jordan' },
  { code: 81, countryCode: 'JP', description: 'Japan' },
  { code: 254, countryCode: 'KE', description: 'Kenya' },
  { code: 996, countryCode: 'KG', description: 'Kyrgyzstan' },
  { code: 855, countryCode: 'KH', description: 'Cambodia' },
  { code: 686, countryCode: 'KI', description: 'Kiribati' },
  { code: 269, countryCode: 'KM', description: 'Comoros' },
  { code: 1, countryCode: 'KN', description: 'St Kitts & Nevis' },
  { code: 850, countryCode: 'KP', description: 'North Korea' },
  { code: 82, countryCode: 'KR', description: 'South Korea' },
  { code: 965, countryCode: 'KW', description: 'Kuwait' },
  { code: 1, countryCode: 'KY', description: 'Cayman Islands' },
  { code: 7, countryCode: 'KZ', description: 'Kazakhstan' },
  { code: 856, countryCode: 'LA', description: 'Laos' },
  { code: 961, countryCode: 'LB', description: 'Lebanon' },
  { code: 1, countryCode: 'LC', description: 'St Lucia' },
  { code: 423, countryCode: 'LI', description: 'Liechtenstein' },
  { code: 94, countryCode: 'LK', description: 'Sri Lanka' },
  { code: 231, countryCode: 'LR', description: 'Liberia' },
  { code: 266, countryCode: 'LS', description: 'Lesotho' },
  { code: 370, countryCode: 'LT', description: 'Lithuania' },
  { code: 352, countryCode: 'LU', description: 'Luxembourg' },
  { code: 371, countryCode: 'LV', description: 'Latvia' },
  { code: 218, countryCode: 'LY', description: 'Libya' },
  { code: 212, countryCode: 'MA', description: 'Morocco' },
  { code: 377, countryCode: 'MC', description: 'Monaco' },
  { code: 373, countryCode: 'MD', description: 'Moldova' },
  { code: 382, countryCode: 'ME', description: 'Montenegro' },
  { code: 590, countryCode: 'MF', description: 'St Martin' },
  { code: 261, countryCode: 'MG', description: 'Madagascar' },
  { code: 692, countryCode: 'MH', description: 'Marshall Islands' },
  { code: 389, countryCode: 'MK', description: 'Macedonia' },
  { code: 223, countryCode: 'ML', description: 'Mali' },
  { code: 95, countryCode: 'MM', description: 'Myanmar (Burma)' },
  { code: 976, countryCode: 'MN', description: 'Mongolia' },
  { code: 853, countryCode: 'MO', description: 'Macau SAR China' },
  { code: 1, countryCode: 'MP', description: 'Northern Mariana Islands' },
  { code: 596, countryCode: 'MQ', description: 'Martinique' },
  { code: 222, countryCode: 'MR', description: 'Mauritania' },
  { code: 1, countryCode: 'MS', description: 'Montserrat' },
  { code: 356, countryCode: 'MT', description: 'Malta' },
  { code: 230, countryCode: 'MU', description: 'Mauritius' },
  { code: 960, countryCode: 'MV', description: 'Maldives' },
  { code: 265, countryCode: 'MW', description: 'Malawi' },
  { code: 52, countryCode: 'MX', description: 'Mexico' },
  { code: 60, countryCode: 'MY', description: 'Malaysia' },
  { code: 258, countryCode: 'MZ', description: 'Mozambique' },
  { code: 264, countryCode: 'NA', description: 'Namibia' },
  { code: 687, countryCode: 'NC', description: 'New Caledonia' },
  { code: 227, countryCode: 'NE', description: 'Niger' },
  { code: 672, countryCode: 'NF', description: 'Norfolk Island' },
  { code: 234, countryCode: 'NG', description: 'Nigeria' },
  { code: 505, countryCode: 'NI', description: 'Nicaragua' },
  { code: 31, countryCode: 'NL', description: 'Netherlands' },
  { code: 47, countryCode: 'NO', description: 'Norway' },
  { code: 977, countryCode: 'NP', description: 'Nepal' },
  { code: 674, countryCode: 'NR', description: 'Nauru' },
  { code: 683, countryCode: 'NU', description: 'Niue' },
  { code: 64, countryCode: 'NZ', description: 'New Zealand' },
  { code: 968, countryCode: 'OM', description: 'Oman' },
  { code: 507, countryCode: 'PA', description: 'Panama' },
  { code: 51, countryCode: 'PE', description: 'Peru' },
  { code: 689, countryCode: 'PF', description: 'French Polynesia' },
  { code: 675, countryCode: 'PG', description: 'Papua New Guinea' },
  { code: 63, countryCode: 'PH', description: 'Philippines' },
  { code: 92, countryCode: 'PK', description: 'Pakistan' },
  { code: 48, countryCode: 'PL', description: 'Poland' },
  { code: 508, countryCode: 'PM', description: 'St Pierre & Miquelon' },
]
