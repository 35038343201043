import React from 'react'
import { Group, Item, Image, TextButton, createChain } from '@revolut/ui-kit'

type Props = {
  title: string
  tryAgainLabel: string
  enterAddressManuallyLabel: string
  orLabel: string
  onTryAgain: () => void
  onEnterAddressManually: () => void
}

export const ErrorView = ({
  title,
  tryAgainLabel,
  enterAddressManuallyLabel,
  orLabel,
  onTryAgain,
  onEnterAddressManually,
}: Props) => {
  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Image src="https://assets.revolut.com/assets/3d-images/3D083@2x.png" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title color="grey-tone-50">{title}</Item.Title>
          <Item.Description>
            {createChain(' ')(
              <TextButton onClick={onTryAgain}>{tryAgainLabel}</TextButton>,
              orLabel,
              <TextButton onClick={onEnterAddressManually}>
                {enterAddressManuallyLabel}
              </TextButton>,
            )}
          </Item.Description>
        </Item.Content>
      </Item>
    </Group>
  )
}
