import React, { useMemo } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'

type Props = {
  children: React.ReactNode
}

export const ReactQueryProvider = ({ children }: Props) => {
  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
        },
      },
    })
  }, [])

  // @ts-ignore Need to test react-query with react 18
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
