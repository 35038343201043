import React, { FC, MouseEventHandler } from 'react'
import { Button, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const BUTTON_CLOSE_MODAL_TESTID = 'button-close-modal-testid'

const StyledButton = styled(Button)`
  @media ${Token.media.sm} {
    margin-top: ${Token.size.s16};
    margin-right: ${Token.size.s16};
  }
  @media ${Token.media.md} {
    margin-top: ${Token.size.s24};
    margin-right: ${Token.size.s24};
  }
`

const CloseModalButton: FC<Props> = ({ onClick }) => (
  <StyledButton
    data-testid={BUTTON_CLOSE_MODAL_TESTID}
    ml="auto"
    title="Close"
    useIcon="Cross"
    variant="white"
    onClick={onClick}
  />
)

export default CloseModalButton
