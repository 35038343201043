import { AddressInputValue } from 'types'

export const prepareDefaultValues = (countryCode: string): AddressInputValue => {
  switch (countryCode) {
    case 'SG':
      return {
        country: countryCode,
        city: 'Singapore',
        postcode: '',
        streetLine1: '',
        streetLine2: '',
      }
    default:
      return {
        country: countryCode,
        streetLine1: '',
        streetLine2: '',
        city: '',
        postcode: '',
        region: '',
      }
  }
}
