import React from 'react'

import { Item, Avatar, ItemSkeleton, Image } from '@revolut/ui-kit'
import { CardType } from 'types'
import { useCardQuery } from './useCardQuery'
import { ItemError } from '../common'

type Props = {
  cardId: string
  cardType: CardType
}

const CardCell = ({ cardId, cardType }: Props) => {
  const cardQuery = useCardQuery(cardId, cardType)

  switch (cardQuery.status) {
    case 'loading':
      return <ItemSkeleton />
    case 'success': {
      const { id, title, subtitle, imageUrl } = cardQuery.data

      return (
        <Item key={id}>
          <Item.Avatar>
            <Avatar color="white">
              <Image borderRadius="2px" src={imageUrl} />
            </Avatar>
          </Item.Avatar>

          <Item.Content>
            <Item.Title id={`${cardId}-title`}>{title}</Item.Title>
            <Item.Description id={`${cardId}-description`}>{subtitle}</Item.Description>
          </Item.Content>
        </Item>
      )
    }
    case 'error':
      return <ItemError onRetry={() => cardQuery.refetch()} />
    case 'idle':
    default:
      return null
  }
}

export default CardCell
