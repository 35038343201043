import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Flex, Header, HeaderSkeleton, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

import { noop } from 'lodash'
import { ErrorPage } from '../ErrorPage'
import { NotFoundPage } from '../NotFoundPage'
import { NoCameraPage } from '../NoCameraPage'
import {
  FlowContextProvider,
  useIsWidgetMode,
  NavigationObstacleContext,
} from '../providers'
import { FlowViewType } from '../appConstants'

import ControlsView from './ControlsView'
import useFlowPage from './useFlowPage'
import { FlowStatusDialog } from './FlowStatusDialog'
import FlowPrompt from './FlowPrompt'
import { EntityType } from '../types'

type Props = {
  flowId: string
  onFlowComplete?: (...args: any[]) => void
  onFlowAbort?: () => void
  onBackButtonClick?: () => void
  onFlowViewChange?: () => void
  openEntityDetails?: (entityId: string, entityType: EntityType) => void
  openTransactionDetails?: (transactionId: string) => void
}

const ContainerStyled = styled(Flex)<{ isWidgetMode?: boolean }>`
  flex-direction: column;
  margin: 0 auto;
  min-height: 100%;
  width: ${props => (props.isWidgetMode ? '100%' : '536px')};
  padding: 0;

  @media not ${Token.media.md} {
    width: 100%;
    padding: ${props => (props.isWidgetMode ? '0' : '0 1rem')};
  }
`

export const LOADER_TESTID = 'loader-testid'

export const FlowPage: FC<Props> = ({
  flowId,
  onFlowComplete,
  onFlowAbort,
  onBackButtonClick,
  onFlowViewChange,
  openEntityDetails,
  openTransactionDetails,
}) => {
  const [isMoveToTheNextViewPending, setIsMoveToTheNextViewPending] = useState(false)
  const isWidgetMode = useIsWidgetMode()
  const { navigationObstacle } = useContext(NavigationObstacleContext)
  const [
    {
      currentView,
      currentPrompt,
      hasPreviousView,
      errorText,
      statusDialogView,
      history,
      isFetching,
      isFlowNotFound,
      isNoCamera,
      isTransition,
      isViewDataFetching,
      isFlowDone,
    },
    {
      selectViewAction,
      changeViewItemValues,
      moveToTheNextView,
      moveToThePreviousView,
      setViewDataFetching,
      abortFlow,
      hideCurrentPrompt,
    },
  ] = useFlowPage({
    flowId,
    onFlowComplete,
    onFlowAbort,
    onBackButtonClick,
    onFlowViewChange,
  })

  const backHidden = history.length === 0 && !hasPreviousView && !onBackButtonClick

  useEffect(() => {
    // Do move after the state got settled.
    if (isMoveToTheNextViewPending) {
      moveToTheNextView()
      setIsMoveToTheNextViewPending(false)
    }
  }, [isMoveToTheNextViewPending, moveToTheNextView])

  const tryMoveToTheNextView = useCallback(() => {
    if (navigationObstacle) {
      navigationObstacle(moveToTheNextView)
    } else {
      setIsMoveToTheNextViewPending(true)
    }
  }, [moveToTheNextView, navigationObstacle])

  const handleActionButtonClick = useCallback(
    (actionId: string | undefined) => {
      if (actionId) {
        selectViewAction(actionId)
      }

      tryMoveToTheNextView()
    },
    [selectViewAction, tryMoveToTheNextView],
  )

  if (isFlowNotFound) {
    return <NotFoundPage />
  }

  if (isNoCamera) {
    return <NoCameraPage />
  }

  if (errorText) {
    return isWidgetMode ? (
      <ContainerStyled isWidgetMode>
        <Header variant="item">
          {!backHidden && <Header.BackButton onClick={moveToThePreviousView} />}
        </Header>
        <ErrorPage text={errorText} />
      </ContainerStyled>
    ) : (
      <ErrorPage text={errorText} />
    )
  }

  if (isFetching) {
    return (
      <ContainerStyled isWidgetMode={isWidgetMode}>
        <HeaderSkeleton data-testid={LOADER_TESTID}>
          <HeaderSkeleton.BackButton onClick={noop} />
          <HeaderSkeleton.Title />
          <HeaderSkeleton.Subtitle />
        </HeaderSkeleton>
      </ContainerStyled>
    )
  }

  return (
    <FlowContextProvider flowId={flowId} moveToTheNextView={moveToTheNextView}>
      <ContainerStyled isWidgetMode={isWidgetMode}>
        {currentView && currentView.type === FlowViewType.Form && (
          <ControlsView
            key={currentView.id}
            changeViewItemValues={changeViewItemValues}
            isTransition={isTransition}
            setDataFetching={setViewDataFetching}
            view={currentView}
            onActionButtonClick={handleActionButtonClick}
            isFlowDone={isFlowDone}
            backHidden={backHidden}
            isViewDataFetching={isViewDataFetching}
            openEntityDetails={openEntityDetails}
            openTransactionDetails={openTransactionDetails}
            onBack={moveToThePreviousView}
          />
        )}
      </ContainerStyled>
      <FlowStatusDialog
        statusDialogView={statusDialogView}
        moveToTheNextView={moveToTheNextView}
        abortFlow={abortFlow}
      />
      <FlowPrompt
        promptView={currentPrompt}
        onClose={hideCurrentPrompt}
        onActionButtonClick={handleActionButtonClick}
      />
    </FlowContextProvider>
  )
}
