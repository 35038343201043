import { useQuery, UseQueryResult } from 'react-query'

import { CountryStates } from '../../../../../types'
import { useApi } from '../../../../../providers'

export const useCountryStates = ({
  countryCode,
}: {
  countryCode: 'AU' | 'US'
}): UseQueryResult<CountryStates, unknown> => {
  const api = useApi()

  const countryStatesQuery = useQuery(['countryStates', countryCode], () =>
    api.getCountryStates({ countryCode }),
  )

  return countryStatesQuery
}
