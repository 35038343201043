import React, { useState } from 'react'
import { AddressInputValue } from '../../../../types'
import { SearchInput } from '../SearchInput'
import { useAddressValuesBySuggestion } from './useAddressValuesBySuggestion'
import { Popup } from './Popup'

type Props = {
  label: string
  countryCode: string
  cancelButtonLabel: string
  searchInputPlaceholder: string
  emptySearchResultsTitle: string
  myAddressIsNotHereLabel: string
  noResultTitle: string
  errorTitle: string
  tryAgainLabel: string
  enterAddressManuallyLabel: string
  orLabel: string
  onSelect: (value: AddressInputValue) => void
  onEnterAddressManually: () => void
}

export const Input = ({
  label,
  cancelButtonLabel,
  searchInputPlaceholder,
  emptySearchResultsTitle,
  countryCode,
  myAddressIsNotHereLabel,
  noResultTitle,
  errorTitle,
  tryAgainLabel,
  enterAddressManuallyLabel,
  orLabel,
  onSelect,
  onEnterAddressManually,
}: Props) => {
  const [addressId, setAddressId] = useState('')
  const { isLoading } = useAddressValuesBySuggestion(countryCode, addressId, onSelect)

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  return (
    <>
      <SearchInput
        pending={isLoading}
        disabled={isLoading}
        label={label}
        onClick={() => setIsPopupOpen(true)}
      />
      <Popup
        isOpen={isPopupOpen}
        countryCode={countryCode}
        cancelButtonLabel={cancelButtonLabel}
        searchInputPlaceholder={searchInputPlaceholder}
        emptySearchResultsTitle={emptySearchResultsTitle}
        myAddressIsNotHereLabel={myAddressIsNotHereLabel}
        noResultTitle={noResultTitle}
        errorTitle={errorTitle}
        tryAgainLabel={tryAgainLabel}
        enterAddressManuallyLabel={enterAddressManuallyLabel}
        orLabel={orLabel}
        onClose={() => {
          setIsPopupOpen(false)
        }}
        onEnterAddressManually={() => {
          setIsPopupOpen(false)
          onEnterAddressManually()
        }}
        onSelectAddress={addressId => {
          setIsPopupOpen(false)
          setAddressId(addressId)
        }}
      />
    </>
  )
}
