import { useQuery } from 'react-query'

import { useApi, useFlowId } from '../../../providers'

export const usePreselectedTransactions = (
  itemId: string,
  preselectedTransactionIds: string[],
) => {
  const api = useApi()
  const flowId = useFlowId()

  const { data: preselectedTransactions = [] } = useQuery(
    ['preselected-transactions', itemId, preselectedTransactionIds],
    () =>
      api.getTransactions({
        flowId,
        itemId,
        createdBefore: Date.now(),
        searchKeyword: '',
        ...(preselectedTransactionIds.length
          ? { transactionIds: preselectedTransactionIds }
          : {}),
      }),
    {
      staleTime: Infinity,
      select: ({ transactions }) => transactions,
      enabled: !!preselectedTransactionIds.length,
    },
  )

  return { preselectedTransactions }
}
