import { Box, Token } from '@revolut/ui-kit'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

export const ControlItemStyled = styled(Box)<{ fullWidth?: boolean }>`
  max-width: ${ifProp('fullWidth', '100%', '33.5rem')};

  @media not ${Token.media.md} {
    margin-left: 0;
    margin-right: 0;
  }
`
