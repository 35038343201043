import React from 'react'
import { StatusPopup, Button } from '@revolut/ui-kit'
import { useHistory } from 'react-router-dom'

import { StatusDialogView } from 'types'

const ExitDialog = ({
  statusDialogView,
  onDialogClose,
}: {
  statusDialogView: StatusDialogView
  onDialogClose: () => void
}) => {
  const history = useHistory()
  const onExit = () => {
    statusDialogView?.dialogActionHyperlink
      ? history.push(statusDialogView.dialogActionHyperlink)
      : onDialogClose()
  }

  return (
    <StatusPopup variant="error" open onClose={onExit}>
      <StatusPopup.Title>
        {statusDialogView?.title || 'You’ve reached the end of the form'}
      </StatusPopup.Title>
      <StatusPopup.Description>
        {statusDialogView?.description || ''}
      </StatusPopup.Description>
      <StatusPopup.Actions>
        <Button variant="secondary" onClick={onExit}>
          {statusDialogView?.dialogActionCaption || 'Close form'}
        </Button>
      </StatusPopup.Actions>
    </StatusPopup>
  )
}

export default ExitDialog
