import React from 'react'

import {
  Group,
  Cell,
  Icon,
  Bullet,
  HStack,
  VStack,
  Text,
  Description,
  Link,
  Subheader,
  ActionButton,
} from '@revolut/ui-kit'

import { ListItem } from '../../../types'
import { DEFAULT_LIST_ICON } from '../../../appConstants'

type Props = ListItem

const List = ({ style, linkStyle = 'LINK', items, title }: Props) => (
  <>
    {title && <Subheader>{title}</Subheader>}
    <Group>
      {items.map(({ id, title, description, icon, link }, index) => (
        <Cell key={id}>
          <HStack space="s-16">
            {style === 'NUMBERED' && <Bullet>{index + 1}</Bullet>}
            {style === 'ICONIZED' && (
              <Icon size={24} name={icon?.name ?? DEFAULT_LIST_ICON} />
            )}
            <VStack>
              <Text variant="primary">{title}</Text>
              {description && <Description>{description}</Description>}
              {link && linkStyle === 'LINK' && (
                <Link href={link.hyperlink} mt="s-8">
                  <HStack align="center" space="s-8">
                    <Icon name="16/LinkExternal" size={16} />
                    <Text variant="caption" fontWeight={500}>
                      {link.title ?? link.hyperlink}
                    </Text>
                  </HStack>
                </Link>
              )}
              {link && linkStyle === 'BUTTON' && (
                <ActionButton use={Link} href={link.hyperlink} mt="s-8">
                  {link.title ?? link.hyperlink}
                </ActionButton>
              )}
            </VStack>
          </HStack>
        </Cell>
      ))}
    </Group>
  </>
)

export default List
