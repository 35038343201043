import React from 'react'
import { Group, Item, Text } from '@revolut/ui-kit'
import { AddressInputValue } from '../../../../types'

type Props = {
  addressValues: AddressInputValue[]
  myAddressIsNotHereLabel: string
  onSelect: (value: AddressInputValue) => void
  onCancel: () => void
}

export const LoadedView = ({
  addressValues,
  myAddressIsNotHereLabel,
  onCancel,
  onSelect,
}: Props) => (
  <Group>
    <Item use="button" variant="choice" onClick={onCancel}>
      <Text variant="secondary" color="primary">
        {myAddressIsNotHereLabel}
      </Text>
    </Item>
    {addressValues.map(address => {
      const addressString = toAddressValuesString(address)
      return (
        <Item
          key={addressString}
          use="button"
          variant="choice"
          onClick={() => onSelect(address)}
        >
          <Item.Content>
            <Item.Title>{addressString}</Item.Title>
          </Item.Content>
        </Item>
      )
    })}
  </Group>
)

const toAddressValuesString = (values: AddressInputValue): string =>
  [values.streetLine1, values.streetLine2, values.region, values.postcode, values.city]
    .filter(Boolean)
    .join(', ')
