import { useQuery, UseQueryResult } from 'react-query'
import { Transaction } from 'types'

import { useApi } from '../../../providers'

export const useTransactionQuery = (
  transactionId: string,
): UseQueryResult<Transaction, unknown> => {
  const api = useApi()

  const transactionQuery = useQuery(['transaction', transactionId], () =>
    api.getTransaction(transactionId),
  )

  return transactionQuery
}
