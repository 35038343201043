import React from 'react'

import { Input } from '@revolut/ui-kit'
import { StringValue, Validation } from 'types'

type Props = {
  label: string
  placeholder: string
  validation: Validation
  value?: StringValue
  required: boolean
  disabled: boolean
  changeValue: (value: string) => void
  isFlowSubmitted: boolean
}

const SignatureInput = ({
  label,
  placeholder,
  validation,
  value,
  required,
  disabled,
  changeValue,
  isFlowSubmitted,
}: Props) => {
  const inputValue = value?.value || ''

  return (
    <Input
      value={inputValue}
      label={label}
      errorMessage={validation.message}
      aria-invalid={Boolean(
        required && isFlowSubmitted && !inputValue.match(new RegExp(validation.pattern)),
      )}
      placeholder={placeholder}
      disabled={disabled}
      onChange={e => changeValue(e.currentTarget.value)}
    />
  )
}

export default SignatureInput
