import { useQuery, UseQueryResult } from 'react-query'
import { Card, CardType } from 'types'

import { useApi, useFlowId } from '../../../providers'

export const useCardQuery = (
  cardId: string,
  cardType: CardType,
): UseQueryResult<Card, unknown> => {
  const api = useApi()
  const flowId = useFlowId()

  const cardQuery = useQuery(['card', cardId, cardType], () =>
    api.getCard(flowId, cardId, cardType),
  )

  return cardQuery
}
