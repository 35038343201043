import React, { FC, useMemo } from 'react'
import Infinite from 'react-infinite'
import { useWindowHeight } from '@react-hook/window-size'
import { Box, Group, Item, Search } from '@revolut/ui-kit'
import { Country } from '../../../../types'
import { includesSubstringCaseInsensitive } from '../../../helpers'
import { FALLBACK_COUNTRIES } from '../constants/fallbackCountries'
import CountryFlag from '../CountryFlag/CountryFlag'
import { useDebouncedSearch } from '../hooks/useDebouncedSearch'

const ELEMENT_HEIGHT = 76
const CONTAINER_HEIGHT_DELTA = 285

const searchCountries = (countries: Country[], filterValue: string) =>
  countries.filter(country =>
    includesSubstringCaseInsensitive(country.description, filterValue),
  )

type Props = {
  disabled: boolean
  countries: Country[]
  searchHint?: string
  onSelect: (value: string) => void
}

const CountrySelectionList: FC<Props> = ({
  countries = FALLBACK_COUNTRIES,
  searchHint,
  disabled,
  onSelect,
}) => {
  const windowHeight = useWindowHeight()

  const { filterInputValue, filteredItems, debouncedSearch } = useDebouncedSearch<
    Country
  >(countries, searchCountries)

  const listHeight = useMemo(
    () =>
      Math.min(
        windowHeight - CONTAINER_HEIGHT_DELTA,
        filteredItems.length * ELEMENT_HEIGHT,
      ),
    [filteredItems.length, windowHeight],
  )

  return (
    <>
      <Box mb="s-16">
        <Search
          value={filterInputValue}
          placeholder={searchHint || 'Search country'}
          onChange={debouncedSearch}
        />
      </Box>
      <Group>
        {filteredItems.length > 0 && (
          <Infinite containerHeight={listHeight} elementHeight={ELEMENT_HEIGHT}>
            {filteredItems.map(({ code, description }) => (
              <Item
                key={code}
                use="button"
                disabled={disabled}
                onClick={() => onSelect(code)}
              >
                <Item.Avatar>
                  <CountryFlag countryCode={code} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{description}</Item.Title>
                </Item.Content>
              </Item>
            ))}
          </Infinite>
        )}
      </Group>
    </>
  )
}

export default CountrySelectionList
