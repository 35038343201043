import React, { useCallback, useState } from 'react'
import { AddressInputValue } from '../../../../types'
import { Input } from './Input'
import { hasSomeAddressValueOtherThanCountry } from '../helpers/hasSomeValuesOtherThanCountry'
import { AddressFields } from '../AddressFields'
import { AddressInputValidationErrorMessages } from '../helpers/mapErrorToErrorMessages'

type Props = {
  label: string
  countryCode: string
  cancelButtonLabel: string
  searchInputPlaceholder: string
  emptySearchResultsTitle: string
  myAddressIsNotHereLabel: string
  noResultTitle: string
  errorTitle: string
  tryAgainLabel: string
  enterAddressManuallyLabel: string
  cityHint: string
  postcodeHint: string
  regionHint: string
  streetLine1Hint: string
  streetLine2Hint: string
  optionalHint: string
  orLabel: string
  value: AddressInputValue
  errorMessages: AddressInputValidationErrorMessages
  onChange: (value: AddressInputValue) => void
}

export const SearchAddressBySubstring = ({
  label,
  cancelButtonLabel,
  searchInputPlaceholder,
  emptySearchResultsTitle,
  myAddressIsNotHereLabel,
  noResultTitle,
  errorTitle,
  tryAgainLabel,
  enterAddressManuallyLabel,
  orLabel,
  cityHint,
  streetLine1Hint,
  streetLine2Hint,
  regionHint,
  postcodeHint,
  optionalHint,
  value,
  errorMessages,
  onChange,
}: Props) => {
  const [isAddressValuesFormVisible, setIsAddressValuesFormVisible] = useState(
    () => value && hasSomeAddressValueOtherThanCountry(value),
  )

  const handleAddressSuggestionSelect = useCallback(
    (value: AddressInputValue) => {
      setIsAddressValuesFormVisible(true)
      onChange(value)
    },
    [onChange],
  )

  const handleEnterAddressManually = useCallback(() => {
    setIsAddressValuesFormVisible(true)
  }, [])

  return (
    <>
      <Input
        label={label}
        countryCode={value.country}
        cancelButtonLabel={cancelButtonLabel}
        searchInputPlaceholder={searchInputPlaceholder}
        emptySearchResultsTitle={emptySearchResultsTitle}
        myAddressIsNotHereLabel={myAddressIsNotHereLabel}
        noResultTitle={noResultTitle}
        errorTitle={errorTitle}
        tryAgainLabel={tryAgainLabel}
        enterAddressManuallyLabel={enterAddressManuallyLabel}
        orLabel={orLabel}
        onSelect={handleAddressSuggestionSelect}
        onEnterAddressManually={handleEnterAddressManually}
      />
      {isAddressValuesFormVisible && (
        <AddressFields
          withPostcode
          value={value}
          cityHint={cityHint}
          postcodeHint={postcodeHint}
          streetLine1Hint={streetLine1Hint}
          streetLine2Hint={streetLine2Hint}
          regionHint={regionHint}
          optionalHint={optionalHint}
          errorTitle={errorTitle}
          tryAgainLabel={tryAgainLabel}
          cancelButtonLabel={cancelButtonLabel}
          errorMessages={errorMessages}
          onChange={onChange}
        />
      )}
    </>
  )
}
