import React from 'react'
import { StatusPopup, Button } from '@revolut/ui-kit'
import { StatusDialogView } from 'types'
import { useHistory } from 'react-router-dom'

const SuccessDialog = ({
  statusDialogView,
  onDialogClose,
}: {
  statusDialogView: StatusDialogView
  onDialogClose: () => void
}) => {
  const history = useHistory()

  return (
    <StatusPopup variant="success-optional" open onClose={onDialogClose}>
      <StatusPopup.Title>
        {statusDialogView?.title || 'Thank you for your submission'}
      </StatusPopup.Title>
      <StatusPopup.Description>
        {statusDialogView?.description || ''}
      </StatusPopup.Description>
      {statusDialogView?.dialogActionCaption && statusDialogView?.dialogActionHyperlink && (
        <StatusPopup.Actions>
          <Button
            variant="secondary"
            onClick={() => {
              if (!statusDialogView.dialogActionHyperlink) return
              history.push(statusDialogView.dialogActionHyperlink)
            }}
          >
            {statusDialogView.dialogActionCaption}
          </Button>
        </StatusPopup.Actions>
      )}
    </StatusPopup>
  )
}

export default SuccessDialog
