import { useQuery, UseQueryResult } from 'react-query'

import { AddressInputValue } from '../../../../types'
import { useApi } from '../../../../providers'

export const useAddressValuesBySuggestion = (
  countryCode: string,
  addressId: string,
  onSuccess: (data: AddressInputValue) => void,
): UseQueryResult<AddressInputValue, unknown> => {
  const api = useApi()

  const addressValuesBySuggestionQuery = useQuery(
    ['addressValueBySuggestion', countryCode, addressId],
    () => api.getAddressValuesBySuggestion({ countryCode, addressId }),
    {
      enabled: !!addressId && addressId.length > 0,
      onSuccess,
    },
  )

  return addressValuesBySuggestionQuery
}
