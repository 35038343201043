import {
  useVirtualViewport,
  Popup as UIKitPopup,
  Header,
  Search,
  Box,
  Group,
  Item,
  HStack,
  Ellipsis,
  Text,
} from '@revolut/ui-kit'
import React, { useMemo, useRef, useState } from 'react'
import { Country } from '../../../../types'
import { CountryAvatar } from './CountryAvatar'

const ITEM_HEIGHT = 76
const VIRTUAL_LIST_OVERSCAN = 16

const itemSize = () => ITEM_HEIGHT

type Props = {
  countries: Country[]
  countryCode: string | null
  title: string
  searchPlaceholder: string
  noResultHint: string
  onCountrySelected: (country: string) => void
  onExit: () => void
}

export const Popup = ({
  searchPlaceholder,
  countries,
  countryCode,
  title,
  noResultHint,
  onCountrySelected,
  onExit,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const search = searchTerm.toLocaleLowerCase()

  const parentRef = useRef<HTMLDivElement | null>(null)
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const foundCountries = useMemo(
    () =>
      countries
        .filter(({ code, description }) => {
          return (
            code.toLocaleLowerCase().includes(search) ||
            description.toLocaleLowerCase().includes(search)
          )
        })
        .sort((countryA: Country, countryB: Country) => {
          if (countryA.code === countryCode) {
            return -1
          }

          if (countryB.code === countryCode) {
            return 1
          }

          return countryA.description.localeCompare(countryB.description)
        }),
    [countries, search, countryCode],
  )

  const keyExtractor = (index: number) => foundCountries[index].code

  const list = useVirtualViewport({
    parentRef,
    scrollRef,
    size: foundCountries.length,
    estimateSize: itemSize,
    overscan: VIRTUAL_LIST_OVERSCAN,
    keyExtractor,
  })

  return (
    <UIKitPopup
      open
      scrollRef={scrollRef}
      onClose={onExit}
      variant="bottom-sheet"
      shouldKeepMaxHeight
    >
      <Header variant="bottom-sheet">
        <Header.Title>{title}</Header.Title>
      </Header>

      <Search
        type="search"
        data-testid="country-search-input"
        autoFocus
        placeholder={searchPlaceholder}
        // There is no access to header title's id, so no possibility to use aria-labelledby instead
        aria-label={title}
        value={searchTerm}
        onChange={(value: string) => {
          setSearchTerm(value)
        }}
      />

      {foundCountries.length ? (
        <Box mt="s-16">
          <Group
            ref={parentRef}
            style={{ position: 'relative', height: `${list.totalSize}px` }}
          >
            {list.virtualItems.map(virtualItem => {
              const countryItem = foundCountries[virtualItem.index]

              if (!countryItem) return null

              const selected = countryItem.code === countryCode
              const titleId = `country-picker-title-id-${countryItem.code}`

              return (
                <Item
                  key={keyExtractor(virtualItem.index)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    minHeight: `${virtualItem.size}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                  ref={virtualItem.measureRef}
                  use="button"
                  onClick={() => onCountrySelected(countryItem.code)}
                  aria-pressed={selected}
                  aria-labelledby={titleId}
                >
                  <Item.Avatar>
                    <CountryAvatar countryCode={countryItem.code} selected={selected} />
                  </Item.Avatar>
                  <Item.Content>
                    <HStack space="s-32">
                      <Text variant="primary" id={titleId}>
                        <Ellipsis>{countryItem.description}</Ellipsis>
                      </Text>
                    </HStack>
                  </Item.Content>
                </Item>
              )
            })}
          </Group>
        </Box>
      ) : (
        <Box mt="s-16" ref={parentRef}>
          <Text variant="h4" textAlign="center">
            {noResultHint}
          </Text>
        </Box>
      )}
    </UIKitPopup>
  )
}
