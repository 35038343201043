import { EntitiesSelectionItemValue, TransactionsValue } from 'library'
import { isEmpty } from 'lodash'
import validator from 'validator'

import { validateAddressValue } from '../Controls/AddressInput/helpers/validateAddressValue'
import { FlowViewItemType } from '../../appConstants'
import {
  MoneyValue,
  PhoneValue,
  FilesValue,
  CardSelectionValue,
  FlowViewItemDynamicSingle,
  SingleSelectionValue,
  MultiSelectionValue,
  AddressInputValue,
} from '../../types'
import { GroupedViewItem } from './utils'

export default function validateValue(groupedItem: GroupedViewItem) {
  switch (groupedItem.type) {
    case 'GROUP': {
      const { group } = groupedItem

      return group.items.some(item => {
        if (!item.required) return true

        switch (item.type) {
          case FlowViewItemType.MultiChoiceLegacy:
          case FlowViewItemType.SingleChoiceLegacy:
            return item.value?.value === true
          case FlowViewItemType.SingleChoiceTextInputLegacy:
            return item.value?.selected && item.value?.content.value?.length
          default: {
            const _: never = item
            return _
          }
        }
      })
    }
    case 'SINGLE': {
      const item = groupedItem.item as FlowViewItemDynamicSingle
      const value = item.value

      // value.value property per API design
      const innerValue = (value as any)?.value
      const isNotRequired = !item.required

      if (isNotRequired && !innerValue && !value) {
        return true
      }

      if (!value) {
        return false
      }

      switch (item.type) {
        case FlowViewItemType.AddressInput:
          return (
            Object.values(validateAddressValue(value as AddressInputValue)).filter(
              Boolean,
            ).length === 0
          )
        case FlowViewItemType.CardInput:
          return validator.isCreditCard(innerValue)

        case FlowViewItemType.DateInput:
          return validator.isISO8601(innerValue)

        case FlowViewItemType.TimeInput:
          return /^([2][0-3]|[01]?[0-9]):[0-5][0-9]$/.test(innerValue)

        case FlowViewItemType.CameraImage:
        case FlowViewItemType.FilesUpload:
          return !isEmpty((value as FilesValue).files)

        case FlowViewItemType.MoneyInput:
          return (
            Boolean((value as MoneyValue).currency) &&
            !isNaN((value as MoneyValue).amount)
          )

        case FlowViewItemType.PhoneInput:
          return (
            Boolean((value as PhoneValue).code) &&
            /^[0-9]{5,11}$/.test((value as PhoneValue).number)
          )

        case FlowViewItemType.TransactionInput:
          return (value as TransactionsValue).transactions.length > 0

        case FlowViewItemType.EntitiesSelection:
          return (value as EntitiesSelectionItemValue).optionIds.length > 0

        case FlowViewItemType.TextInput: {
          if (item.validation) {
            return (
              innerValue !== '' && new RegExp(item.validation.pattern).test(innerValue)
            )
          }
          return innerValue !== ''
        }

        case FlowViewItemType.YesNoInput:
          return typeof innerValue === 'boolean'

        case FlowViewItemType.CardSelection:
          return (value as CardSelectionValue).cards.length > 0

        case FlowViewItemType.SignatureInput:
          return (
            typeof innerValue === 'string' &&
            innerValue.match(new RegExp(item.validation.pattern))
          )

        case FlowViewItemType.SingleSelection: {
          const { optionId, customOption } = value as SingleSelectionValue
          const customOptionIsValid =
            typeof customOption === 'string' ? customOption.length > 0 : true
          return optionId !== undefined && customOptionIsValid
        }

        case FlowViewItemType.MultiSelection: {
          const { optionIds, customOption } = value as MultiSelectionValue

          const customOptionIsValid =
            typeof customOption === 'string' ? customOption.length > 0 : true
          const requiredAllOptionsIsValid =
            !item.requiredAllOptionsSelected || optionIds.length >= item.options.length

          return (
            optionIds !== undefined &&
            optionIds.length > 0 &&
            customOptionIsValid &&
            requiredAllOptionsIsValid
          )
        }

        default:
          return Boolean(innerValue)
      }
    }

    default: {
      const _: never = groupedItem
      return _
    }
  }
}
