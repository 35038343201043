import React, { FC } from 'react'
import { Group, ItemSkeleton } from '@revolut/ui-kit'
import {
  EntitiesSelectionItem,
  EntitiesSelectionItemValue,
  EntityType,
} from '../../../types'
import { useEntities } from '../common/Entities/useEntities'
import { EntityItem } from '../common/Entities/EntityItem'

type Props = EntitiesSelectionItem & {
  disabled: boolean
  changeValue(value: EntitiesSelectionItemValue): void
  openEntityDetails?(entityId: string, entityType: EntityType): void
}

const EntitiesSelection: FC<Props> = ({
  value,
  changeValue: onChange,
  disabled,
  id,
  entityType,
  openEntityDetails,
}) => {
  const { enabled, loading, entities } = useEntities(id)

  const handleItemSelection = (itemId: string, selected: boolean) => {
    const selectedOptions: string[] = value?.optionIds ?? []
    if (selected) {
      onChange({ optionIds: [...selectedOptions, itemId] })
    } else {
      onChange({ optionIds: [...selectedOptions.filter(id => id !== itemId)] })
    }
  }

  const entityDetailsSupported = typeof openEntityDetails === 'function'

  const onEntityClick = (entityId: string) => {
    openEntityDetails?.(entityId, entityType)
  }

  if (!enabled) return null
  // TODO FRMBLDR-678 should we support error state with retry? Same for empty array
  if (loading) return <ItemSkeleton />

  return (
    <Group>
      {entities.map(entity => (
        <EntityItem
          selected={!!value && value.optionIds.some(id => entity.id === id)}
          disabled={disabled}
          key={entity.id}
          entity={entity}
          onSelect={handleItemSelection}
          onClick={entityDetailsSupported ? onEntityClick : undefined}
        />
      ))}
    </Group>
  )
}

export default EntitiesSelection
