import React from 'react'

import { ItemSkeleton } from '@revolut/ui-kit'
import { useTransactionQuery } from './useTransactionQuery'
import { TransactionItem } from '../common/TransactionItem'
import { ItemError } from '../common'

type Props = {
  transactionId: string
  openTransactionDetails?: (transactionId: string) => void
  disabled?: boolean
}

const TransactionCell = ({ transactionId, openTransactionDetails, disabled }: Props) => {
  const trasactionQuery = useTransactionQuery(transactionId)

  switch (trasactionQuery.status) {
    case 'loading':
      return <ItemSkeleton />
    case 'success':
      return (
        <TransactionItem
          transaction={trasactionQuery.data}
          openTransactionDetails={openTransactionDetails}
          disabled={disabled}
        />
      )
    case 'error':
      return <ItemError onRetry={() => trasactionQuery.refetch()} />
    case 'idle':
    default:
      return null
  }
}

export default TransactionCell
