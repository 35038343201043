export const COUNTRY_CODE_BY_CURRENCY_CODE = {
  GBP: 'GB',
  EUR: 'EU',
  USD: 'US',
  AUD: 'AU',
  CAD: 'CA',
  CZK: 'CZ',
  DKK: 'DK',
  JPY: 'JP',
  MXN: 'MX',
  PLN: 'PL',
  RON: 'RO',
  SEK: 'SE',
  THB: 'TH',
  AED: 'AE',
  CHF: 'CH',
  HKD: 'HK',
  HUF: 'HU',
  ILS: 'IL',
  NOK: 'NO',
  NZD: 'NZ',
  SGD: 'SG',
  TRY: 'TR',
  ZAR: 'ZA',
  SAR: 'SA',
  BGN: 'BG',
  QAR: 'QA',
  ALL: 'AL',
  AFN: 'AF',
  ARS: 'AR',
  AWG: 'AW',
  AZN: 'AZ',
  BSD: 'BS',
  BBD: 'BB',
  BZD: 'BZ',
  BMD: 'BM',
  BOB: 'BO',
  BAM: 'BA',
  BWP: 'BW',
  BRL: 'BR',
  BND: 'BN',
  KHR: 'KH',
  KYD: 'KY',
  CLP: 'CL',
  CNY: 'CN',
  COP: 'CO',
  CRC: 'CR',
  HRK: 'HR',
  CUC: 'CU',
  DOP: 'DO',
  XCD: 'KN',
  EGP: 'EG',
  SVC: 'SV',
  EEK: 'EE',
  FKP: 'FK',
  FJD: 'FJ',
  GIP: 'GI',
  GTQ: 'GT',
  GGP: 'GG',
  HNL: 'HN',
  ISK: 'IS',
  INR: 'IN',
  IDR: 'ID',
  IMP: 'IM',
  JMD: 'JM',
  JEP: 'JE',
  KZT: 'KZ',
  KRW: 'KR',
  MAD: 'MA',
  RUB: 'RU',
  MYR: 'MY',
  PHP: 'PH',
  AMD: 'AM',
  ANG: 'CW',
  AOA: 'AO',
  BDT: 'BD',
  BHD: 'BH',
  BYN: 'BY',
  CVE: 'CV',
  DZD: 'DZ',
  ETB: 'ET',
  GEL: 'GE',
  GHS: 'GH',
  GMD: 'GM',
  GNF: 'GN',
  HTG: 'HT',
  IQD: 'IQ',
  JOD: 'JO',
  KES: 'KE',
  KGS: 'KG',
  KWD: 'KW',
  LAK: 'LA',
  LBP: 'LB',
  LKR: 'LK',
  LSL: 'LS',
  MDL: 'MD',
  MGA: 'MG',
  MKD: 'MK',
  MMK: 'MM',
  MOP: 'MO',
  MUR: 'MU',
  MVR: 'MV',
  MWK: 'MW',
  MZN: 'MZ',
  NAD: 'NA',
  NGN: 'NG',
  NIO: 'NI',
  NPR: 'NP',
  OMR: 'OM',
  PAB: 'PA',
  PEN: 'PE',
  PGK: 'PG',
  PKR: 'PK',
  PYG: 'PY',
  RSD: 'RS',
  RWF: 'RW',
  SBD: 'SB',
  SCR: 'SC',
  SRD: 'SR',
  TMT: 'TM',
  TND: 'TN',
  TOP: 'TO',
  TTD: 'TT',
  TWD: 'TW',
  TZS: 'TZ',
  UAH: 'UA',
  UGX: 'UG',
  UYU: 'UY',
  VEF: 'VE',
  VND: 'VN',
  XAF: 'CM',
  XOF: 'SN',
  XPF: 'PF',
  ZMW: 'ZM',
}
