import { subDays } from 'date-fns'
import {
  FilterValueType,
  PropertyFilterType,
  TRANSACTION_COUNT_TO_REQUEST,
  TRANSACTION_PERIOD_DAYS_TO_SUBTRACT,
} from '../../../appConstants'
import { AdditionalFilters, FilterDateRange, PropertyFilter } from '../../../types'

export const extractAdditionalFilters = (propertyFilters: PropertyFilter[]) => {
  const additionalFilters: AdditionalFilters = {}

  for (const filter of propertyFilters) {
    if (
      filter.name === 'mcc' &&
      filter.type === PropertyFilterType.Eq &&
      filter.value.type === FilterValueType.String
    ) {
      additionalFilters.mcc = filter.value.value
    }

    if (
      filter.name === 'chargebackEligible' &&
      filter.type === PropertyFilterType.Eq &&
      filter.value.type === FilterValueType.Boolean
    ) {
      additionalFilters.chargebackEligible = filter.value.value
    }
  }

  return additionalFilters
}

export const extractDateFilterRange = (propertyFilters: PropertyFilter[]) => {
  let dateRange: FilterDateRange | null = null

  for (const filter of propertyFilters) {
    if (filter.name === 'createdDate' && filter.type === PropertyFilterType.Between) {
      dateRange = { from: Number(filter.from), to: Number(filter.to) }
      break
    }
  }

  return dateRange
}

export const createFetchTransactionParams = (
  dateFilter: FilterDateRange | null,
  pageParam: { to?: number },
) => {
  const params: { count: number } & Partial<FilterDateRange> = {
    count: TRANSACTION_COUNT_TO_REQUEST,
  }

  if (dateFilter) {
    params.from = pageParam?.to
      ? Math.max(
          subDays(pageParam.to, TRANSACTION_PERIOD_DAYS_TO_SUBTRACT).getTime(),
          dateFilter.from,
        )
      : dateFilter.from
    params.to = pageParam?.to || dateFilter.to
  } else if (pageParam?.to) {
    params.from = subDays(pageParam.to, TRANSACTION_PERIOD_DAYS_TO_SUBTRACT).getTime()
    params.to = pageParam.to
  }

  return params
}
