import React from 'react'
import { Prompt as UIPrompt, Header, Image } from '@revolut/ui-kit'
import { HtmlStringComponent } from '../Controls/common'
import { PromptView } from '../../types'

import FlowActionButtons from '../FlowActionButtons'

export type Props = {
  promptView: PromptView
  onClose: () => void
  onActionButtonClick: (actionId?: string) => void
}

const Prompt = ({ promptView, onClose, onActionButtonClick }: Props) => {
  const { title, subtitleHtml, image, closeable } = promptView

  return (
    <UIPrompt open onClose={() => closeable && onClose()} preventUserClose={!closeable}>
      <UIPrompt.Header>
        <Header variant="item">
          {closeable && <Header.CloseButton onClick={onClose} />}
          <Header.Title>{title}</Header.Title>
          {subtitleHtml && (
            <Header.Description>
              <HtmlStringComponent htmlString={subtitleHtml} />
            </Header.Description>
          )}
        </Header>
      </UIPrompt.Header>
      {image && <Image src={image.url} size={200} m="auto" />}
      <UIPrompt.Actions>
        <FlowActionButtons onClick={onActionButtonClick} actions={promptView.actions} />
      </UIPrompt.Actions>
    </UIPrompt>
  )
}

export default Prompt
