import { Button, Header, Image, Prompt, StatusPopup } from '@revolut/ui-kit'
import React, { useEffect, useRef, useState } from 'react'

import { AddressValidationResult } from 'types'
import { notReachable } from '../../../helpers'

type State = { type: 'closed' } | AddressValidationResult

export const ValidationResultPopup = ({
  validationResult,
}: {
  validationResult: AddressValidationResult
}) => {
  const ref = useRef(validationResult)
  const [popupState, setPopupState] = useState<State>(validationResult)

  useEffect(() => {
    if (ref.current !== validationResult) {
      ref.current = validationResult
      setPopupState(validationResult)
    }
  }, [validationResult])

  switch (popupState.type) {
    case 'closed':
    case 'success':
      return null
    case 'invalid_address':
    case 'invalid_address_postcode':
      return (
        <StatusPopup
          variant="error"
          open
          onClose={() => setPopupState({ type: 'closed' })}
        >
          <StatusPopup.Title>{popupState.message}</StatusPopup.Title>
          <StatusPopup.Actions>
            <Button variant="secondary" onClick={() => setPopupState({ type: 'closed' })}>
              {popupState.closeButtonLabel}
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>
      )
    case 'ineligible_address':
      return (
        <Prompt
          open
          onClose={() => setPopupState({ type: 'closed' })}
          labelButtonClose={popupState.closeButtonLabel}
        >
          <Prompt.Header>
            <Header variant="item">
              <Header.CloseButton aria-label={popupState.closeButtonLabel} />
              <Header.Title>{popupState.title}</Header.Title>
              <Header.Description>{popupState.description}</Header.Description>
            </Header>
          </Prompt.Header>
          <Image
            src="https://assets.revolut.com/assets/3d-images/3D160@2x.png"
            size={250}
            m="auto"
            role="presentation"
          />
          <Prompt.Actions>
            <Button elevated onClick={() => setPopupState({ type: 'closed' })}>
              {popupState.closeButtonLabel}
            </Button>
          </Prompt.Actions>
        </Prompt>
      )
    default:
      return notReachable(popupState)
  }
}
