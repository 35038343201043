import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useCallback,
  useRef,
  useState,
} from 'react'
import { Button, StatusPopup } from '@revolut/ui-kit'
import ActionCard from '../../common/Cards/ActionCard'
import { getTotalFilesSize, formatBytes } from './utils'

export const FILE_SELECT_CONTROL = 'file-select-control'

type Props = {
  disabled: boolean
  buttonTitle?: string
  buttonDescription?: string
  uploadSizeLimit?: number
  onChange: (fileList: FileList) => void
}

const FileSelectControl: FC<Props> = ({
  disabled,
  onChange,
  buttonTitle,
  buttonDescription,
  uploadSizeLimit,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [openExceededFileSizeError, setOpenExceededFileSizeError] = useState(false)

  const handleUploadFileClick = useCallback(() => {
    if (fileInputRef.current instanceof HTMLInputElement) {
      fileInputRef.current.click()
    }
  }, [])

  const handleUpload = useCallback<ChangeEventHandler>(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target

      if (!files) return

      const totalFilesSize = getTotalFilesSize(files)

      if (uploadSizeLimit && totalFilesSize >= uploadSizeLimit) {
        setOpenExceededFileSizeError(true)
        return
      }

      onChange(files)
    },
    [onChange, uploadSizeLimit],
  )

  // When a new file is the same onChange won't call.
  // The solution below will reset the selected file after every new file/s choosing.
  const resetFileValue = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = ''
  }

  return (
    <>
      <ActionCard
        text={buttonTitle || 'Upload file'}
        description={buttonDescription}
        icon="Plus"
        onClick={handleUploadFileClick}
      />
      <input
        multiple
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        disabled={disabled}
        onChange={handleUpload}
        onClick={resetFileValue}
        data-testid={FILE_SELECT_CONTROL}
      />

      <StatusPopup
        variant="error"
        open={openExceededFileSizeError}
        onClose={() => setOpenExceededFileSizeError(false)}
      >
        {/* TODO: Translate */}
        <StatusPopup.Title>File size too big</StatusPopup.Title>
        <StatusPopup.Description>
          {/* TODO: Translate */}
          The file you selected exceeds {formatBytes(uploadSizeLimit ?? 0)}. Please upload
          another one
        </StatusPopup.Description>
        <StatusPopup.Actions>
          {/* TODO: Translate */}
          <Button onClick={() => setOpenExceededFileSizeError(false)}>Got it</Button>
        </StatusPopup.Actions>
      </StatusPopup>
    </>
  )
}

export default FileSelectControl
