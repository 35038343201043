import React, { FC, Fragment, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Cell,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Search,
  Text,
  Group,
} from '@revolut/ui-kit'

import { includesSubstringCaseInsensitive } from '../../helpers'
import {
  SingleChoiceInputItemLegacy,
  SingleChoiceTextInputItemLegacy,
} from '../../../types'
import { Actions } from '../../useFlowPage'
import {
  CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR,
  FlowViewItemType,
} from '../../../appConstants'
import { useMoveToTheNextView } from '../../../providers'

import useSingleChoice from './useSingleChoice'

export type Props = {
  items: (SingleChoiceInputItemLegacy | SingleChoiceTextInputItemLegacy)[]
  disabled: boolean
  hasCustomActions: boolean
  singleDynamicItemOnView: boolean
  changeViewItemValues: Actions['changeViewItemValues']
}

export const CUSTOM_ANSWER_INPUT_TESTID = 'custom-answer-input-testid'

const SingleChoiceLegacy: FC<Props> = ({
  items = [],
  disabled,
  hasCustomActions,
  changeViewItemValues,
  singleDynamicItemOnView,
}) => {
  const [
    { customValue, showCustomInput, defaultValue },
    { changeRadioGroup, changeCustomValue },
  ] = useSingleChoice({
    items,
    changeViewItemValues,
  })

  const moveToTheNextView = useMoveToTheNextView()

  const [filterValue, setFilterValue] = useState('')
  const [isMoveToTheNextViewPending, setIsMoveToTheNextViewPending] = useState(false)

  const hasTextInputOption = items.some(
    item => item.type === FlowViewItemType.SingleChoiceTextInputLegacy,
  )

  useEffect(() => {
    // Do move after the state got updated.
    if (isMoveToTheNextViewPending) {
      moveToTheNextView()
      setIsMoveToTheNextViewPending(false)
    }
  }, [isMoveToTheNextViewPending, moveToTheNextView])

  const filteredItems = useMemo(
    () =>
      items.filter(item =>
        item.type === FlowViewItemType.SingleChoiceLegacy &&
        typeof item.description === 'string'
          ? includesSubstringCaseInsensitive(item.description, filterValue)
          : true,
      ),
    [filterValue, items],
  )

  return (
    <>
      {items.length > CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR && (
        <Search
          value={filterValue}
          placeholder={items[0]?.searchHint || 'Search'}
          onChange={setFilterValue}
        />
      )}
      <Group mt="s-16">
        <RadioGroup
          onChange={changeRadioGroup}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {({ getInputProps }) =>
            filteredItems.map(item => (
              <Fragment key={item.id}>
                {item.type === FlowViewItemType.SingleChoiceLegacy && (
                  <>
                    {hasTextInputOption ||
                    hasCustomActions ||
                    !singleDynamicItemOnView ? (
                      <Cell use="label">
                        <Radio
                          {...getInputProps({ value: item.id })}
                          data-testid={item.id}
                        >
                          <Text color="foreground">{item.description}</Text>
                        </Radio>
                      </Cell>
                    ) : (
                      <Cell
                        use="button"
                        variant="disclosure"
                        disabled={disabled}
                        onClick={() => {
                          getInputProps({ value: item.id }).onChange()
                          setIsMoveToTheNextViewPending(true)
                        }}
                      >
                        {item.description}
                      </Cell>
                    )}
                  </>
                )}
                {item.type === FlowViewItemType.SingleChoiceTextInputLegacy && (
                  <Flex p="s-16" pt={showCustomInput ? 0 : 's-16'} alignItems="center">
                    <Radio {...getInputProps({ value: item.id })} data-testid={item.id}>
                      {!showCustomInput && (
                        <Text color="foreground">{item?.hint || 'Other'}</Text>
                      )}
                    </Radio>
                    {showCustomInput && (
                      <Box width="100%">
                        <Input
                          autoFocus
                          label={item?.hint || 'Other'}
                          value={customValue}
                          onChange={changeCustomValue}
                          disabled={disabled}
                          data-testid={CUSTOM_ANSWER_INPUT_TESTID}
                        />
                      </Box>
                    )}
                  </Flex>
                )}
              </Fragment>
            ))
          }
        </RadioGroup>
      </Group>
    </>
  )
}

export default SingleChoiceLegacy
