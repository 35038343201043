import React, { useState, useMemo } from 'react'
import {
  Item,
  HStack,
  Box,
  Search,
  Action,
  Popup as UiKitPopup,
  Group,
  Header,
} from '@revolut/ui-kit'

import { CountryStates, CountryState } from '../../../../../types'

type Props = {
  countryStates: CountryStates
  value: CountryState['code'] | undefined
  searchPlaceholder: string
  cancelButtonLabel: string
  onClose: () => void
  onSelect: (value: CountryState['code']) => void
}

export const Popup = ({
  countryStates,
  value,
  searchPlaceholder,
  cancelButtonLabel,
  onClose,
  onSelect,
}: Props) => {
  const [searchString, setSearchString] = useState('')
  const search = searchString.toLowerCase()

  const foundStates = useMemo(() => {
    const currentCountryState = countryStates.find(
      countryState => countryState.code === value,
    )

    return countryStates
      .filter(
        countryState =>
          countryState.name.toLocaleLowerCase().includes(search) ||
          countryState.code.toLocaleLowerCase().includes(search),
      )
      .sort((a, b) => {
        if (a.name === currentCountryState?.name) {
          return -1
        }

        if (b.name === currentCountryState?.name) {
          return 1
        }

        return a.name.localeCompare(b.name)
      })
  }, [countryStates, search, value])

  return (
    <UiKitPopup open closeOnEsc variant="modal-view" onClose={onClose}>
      <Header variant="modal" hidden>
        <Header.Title>{searchPlaceholder}</Header.Title>
      </Header>
      <HStack space="s-16" pb="s-16">
        <Box width={1}>
          <Search
            autoFocus
            placeholder={searchPlaceholder}
            value={searchString}
            onChange={subString => {
              setSearchString(subString)
            }}
          />
        </Box>
        <Action onClick={onClose}>{cancelButtonLabel}</Action>
      </HStack>
      <Group>
        {foundStates.map(countryState => (
          <Item
            key={countryState.code}
            use="button"
            variant="choice"
            aria-pressed={countryState.code === value}
            onClick={() => onSelect(countryState.code)}
          >
            <Item.Content>
              <Item.Title>{countryState.name}</Item.Title>
            </Item.Content>
          </Item>
        ))}
      </Group>
    </UiKitPopup>
  )
}
