import { notReachable } from '../helpers'
import {
  FlowViewItem,
  MultiChoiceItemLegacy,
  SingleChoiceInputItemLegacy,
  SingleChoiceTextInputItemLegacy,
  FlowViewItemDynamicSingle,
  FlowViewItemStatic,
  CountrySelectorDto,
  CountrySelector,
} from '../../types'
import { FlowViewItemType } from '../../appConstants'

export type GroupedViewItem = SingleItem | GroupItem

type SingleItem = {
  type: 'SINGLE'
  item: FlowViewItemDynamicSingle | FlowViewItemStatic
}

export type GroupItem = {
  type: 'GROUP'
  group: MultiChoiceItemGroup | SingleChoiceItemGroup
}

type MultiChoiceItemGroup = {
  itemType: FlowViewItemType.MultiChoiceLegacy
  items: (MultiChoiceItemLegacy | SingleChoiceTextInputItemLegacy)[]
}

type SingleChoiceItemGroup = {
  itemType: FlowViewItemType.SingleChoiceLegacy
  items: (SingleChoiceInputItemLegacy | SingleChoiceTextInputItemLegacy)[]
}

/**
 * Segregate SingleChoice, MultiChoiceLegacy and SingleChoiceTextInput items to separate groups from rest items
 *
 * @example
 * // returns: [
 * //   {type: 'SINGLE', item: {type: FlowViewItemType.MoneyInput}},
 * //   {type: 'GROUP', items: [
 * //     {type: FlowViewItemType.SingleChoice},
 * //     {type: FlowViewItemType.SingleChoice},
 * //     {type: FlowViewItemType.SingleChoiceTextInput},
 * //   ]}
 * // ]
 * groupViewItems([
 *  {type: FlowViewItemType.MoneyInput},
 *  {type: FlowViewItemType.SingleChoice},
 *  {type: FlowViewItemType.SingleChoice},
 *  {type: FlowViewItemType.SingleChoiceTextInput},
 * ])
 */
export const groupViewItems = (viewItems: FlowViewItem[]): GroupedViewItem[] => {
  const result: GroupedViewItem[] = []
  let currentGroup: MultiChoiceItemGroup | SingleChoiceItemGroup | null = null

  const pushCurrentGroup = (group: MultiChoiceItemGroup | SingleChoiceItemGroup) => {
    result.push({
      type: 'GROUP',
      group,
    })
    currentGroup = null
  }

  for (const viewItem of viewItems) {
    switch (viewItem.type) {
      case FlowViewItemType.SingleChoiceLegacy:
        if (
          currentGroup &&
          currentGroup.itemType === FlowViewItemType.SingleChoiceLegacy
        ) {
          currentGroup.items.push(viewItem)
          break
        }

        if (currentGroup) {
          pushCurrentGroup(currentGroup)
        }

        currentGroup = {
          itemType: FlowViewItemType.SingleChoiceLegacy,
          items: [viewItem],
        }
        break
      case FlowViewItemType.MultiChoiceLegacy: {
        if (
          currentGroup &&
          currentGroup.itemType === FlowViewItemType.MultiChoiceLegacy
        ) {
          currentGroup.items.push(viewItem)
          break
        }

        if (currentGroup) {
          pushCurrentGroup(currentGroup)
        }

        currentGroup = {
          itemType: FlowViewItemType.MultiChoiceLegacy,
          items: [viewItem],
        }
        break
      }
      case FlowViewItemType.SingleChoiceTextInputLegacy: {
        if (currentGroup) {
          currentGroup.items.push(viewItem)
        }
        break
      }
      default: {
        if (currentGroup) {
          pushCurrentGroup(currentGroup)
        }
        result.push({ type: 'SINGLE', item: viewItem })
      }
    }
  }

  if (currentGroup) {
    result.push({ type: 'GROUP', group: currentGroup })
  }

  return result
}

export const mapFromCountrySelectorDtoToCountrySelector = (
  countrySelectorDto: CountrySelectorDto,
): CountrySelector => {
  return {
    preselected: countrySelectorDto.preselected,
    // check if value is placeholder to not throw error in Backoffice preview
    visible: !isPlaceholder(countrySelectorDto.visible)
      ? mapToBoolean(countrySelectorDto.visible)
      : true,
    editable: !isPlaceholder(countrySelectorDto.editable)
      ? mapToBoolean(countrySelectorDto.editable)
      : true,
  }
}

const isPlaceholder = (value: string) => {
  return value.startsWith('${')
}

const mapToBoolean = (value: 'true' | 'false'): boolean => {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return notReachable(value)
  }
}
