import { useMemo } from 'react'
import { useInfiniteQuery } from 'react-query'
import { last } from 'lodash'
import { PropertyFilter, Transaction } from 'types'

import { useApi } from '../../../providers'
import {
  createFetchTransactionParams,
  extractAdditionalFilters,
  extractDateFilterRange,
} from './utilsLegacy'

export const useTransactionsLegacy = (propertyFilters: PropertyFilter[]) => {
  const api = useApi()
  const dateFilter = extractDateFilterRange(propertyFilters)
  const additionalFilters = extractAdditionalFilters(propertyFilters)

  const {
    data,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    'transactions',
    ({ pageParam }) => {
      const fetchTransactionParams = createFetchTransactionParams(dateFilter, pageParam)
      return api.getTransactionsLegacy!(fetchTransactionParams, additionalFilters)
    },
    {
      staleTime: Infinity,
      getNextPageParam: (lastPage, pages) => {
        if (pages.length > 1 && !lastPage.length) return undefined
        return { to: last(lastPage)?.createdDate }
      },
    },
  )

  const transactions = useMemo(() => data?.pages?.flat<Transaction[][]>() ?? [], [
    data?.pages,
  ])

  return {
    transactions,
    fetchNextTransactionsPage: fetchNextPage,
    isNextTransactionsPageFetching: isFetchingNextPage,
    isTransactionsFetching: isFetching,
    hasNextPage,
  }
}
