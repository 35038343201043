import React from 'react'
import { Group, ItemSkeleton } from '@revolut/ui-kit'

export const LoadingView = () => (
  <Group>
    {Array.from({ length: 10 }).map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <ItemSkeleton key={i}>
        <ItemSkeleton.Content>
          <ItemSkeleton.Title />
        </ItemSkeleton.Content>
        <ItemSkeleton.Side />
      </ItemSkeleton>
    ))}
  </Group>
)
