import React, { useState } from 'react'
import {
  Input as UiKitInput,
  InputProps as UiKitInputProps,
  IconButton,
} from '@revolut/ui-kit'

import { notReachable } from '../../../../helpers'

import { Popup } from './Popup'
import { CountryStates, CountryState } from '../../../../../types'

type State = { type: 'popup_open' } | { type: 'popup_closed' }
type SelectProps = {
  countryStates: CountryStates
  value: CountryState['code'] | undefined
  countryStateInputValue: CountryState['name']
  placeholder: string
  cancelButtonLabel: string
  onChange: (countryStateCode: CountryState['code']) => void
} & Pick<UiKitInputProps, 'label' | 'placeholder' | 'aria-invalid' | 'message'>

export const Select = ({
  label,
  placeholder,
  cancelButtonLabel,
  value,
  countryStateInputValue,
  countryStates,
  onChange,
  ...inputProps
}: SelectProps) => {
  const [popupState, setPopupState] = useState<State>({ type: 'popup_closed' })

  return (
    <>
      <UiKitInput
        autoComplete="off"
        type="button"
        label={label}
        value={countryStateInputValue}
        renderAction={() => (
          <IconButton
            useIcon="ChevronDown"
            color="grey-50"
            onClick={() => {
              setPopupState({ type: 'popup_open' })
            }}
          />
        )}
        onClick={() => {
          setPopupState({ type: 'popup_open' })
        }}
        {...inputProps}
      />
      {(() => {
        switch (popupState.type) {
          case 'popup_closed':
            return null
          case 'popup_open':
            return (
              <Popup
                countryStates={countryStates}
                searchPlaceholder={placeholder}
                cancelButtonLabel={cancelButtonLabel}
                value={value}
                onClose={() => setPopupState({ type: 'popup_closed' })}
                onSelect={value => {
                  setPopupState({ type: 'popup_closed' })
                  onChange(value)
                }}
              />
            )
          /* istanbul ignore next */
          default:
            return notReachable(popupState)
        }
      })()}
    </>
  )
}
