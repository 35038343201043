import React from 'react'
import * as UIKit from '@revolut/ui-kit'

type Props = {
  open: boolean
  bg: 'black'
  children: React.ReactNode
}

type UIKitCompatibleModal = (props: Props) => React.ReactElement

const module = UIKit as any

// This is hack for ui-kit backward compatibility bettween v13 adn v14
// it's completly the same component but
// in ui-kit v13 it's named Modal and
// in ui-kit v14 it's renamed to Overlay
export const UiKitModal = module.Modal
  ? module.Modal
  : (module.Overlay as UIKitCompatibleModal)
