import React from 'react'

import { StatusDialogView } from 'types'
import SuccessDialog from './SuccessDialog'
import ErrorDialog from './ErrorDialog'
import ExitDialog from './ExitDialog'
import { DialogType } from '../../appConstants'

type Props = {
  statusDialogView?: StatusDialogView
  moveToTheNextView: () => void
  abortFlow: () => void
}

export const FlowStatusDialog = ({
  statusDialogView,
  moveToTheNextView,
  abortFlow,
}: Props) => {
  if (!statusDialogView) return null

  switch (statusDialogView.dialogType) {
    case DialogType.Success:
    case DialogType.Pending:
      return (
        <SuccessDialog
          statusDialogView={statusDialogView}
          onDialogClose={moveToTheNextView}
        />
      )
    case DialogType.Error:
      return <ErrorDialog statusDialogView={statusDialogView} onDialogClose={abortFlow} />
    case DialogType.Exit:
      return <ExitDialog statusDialogView={statusDialogView} onDialogClose={abortFlow} />
    default:
      return null
  }
}
