import { useDebounce } from '@react-hook/debounce'
import { useCallback, useState } from 'react'

const DEBOUNCE_PERIOD = 300

export const useDebouncedInput = <T>(
  initialValue: T,
  delay: number = DEBOUNCE_PERIOD,
) => {
  const [debouncedValue, setDebouncedValue] = useDebounce(initialValue, delay)
  const [value, setValue] = useState(debouncedValue)

  return {
    value,
    debouncedValue,
    setValue: useCallback(
      (newValue: T) => {
        setDebouncedValue(newValue)
        setValue(newValue)
      },
      [setDebouncedValue],
    ),
  }
}
