import React, { useRef } from 'react'
import { Box, ItemSkeleton, useIntersectViewport } from '@revolut/ui-kit'

type Props = {
  onIntersect: () => void
}

export const ItemIntersectionSkeleton = ({ onIntersect }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  useIntersectViewport(ref, isIntersecting => {
    isIntersecting && onIntersect()
  })

  return (
    <Box ref={ref}>
      <ItemSkeleton />
    </Box>
  )
}
