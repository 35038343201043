import { FC } from 'react'
import { useToggle } from '@revolut/ui-kit'
import React from 'react'
import { FormViewSecondaryDescription } from '../../../types'
import { SecondaryDescriptionButton } from './SecondaryDescriptionButton'
import { SecondaryDescriptionDialog } from './SecondaryDescriptionDialog'

type Props = {
  secondaryDescription: FormViewSecondaryDescription
}

export const SecondaryDescription: FC<Props> = ({ secondaryDescription }) => {
  const [open, toggleOpen] = useToggle()

  return (
    <>
      <SecondaryDescriptionButton
        label={secondaryDescription.label}
        onClick={toggleOpen.on}
      />
      <SecondaryDescriptionDialog
        title={secondaryDescription.title}
        subtitle={secondaryDescription.subtitle}
        buttonTitle={secondaryDescription.buttonTitle}
        items={secondaryDescription.items}
        open={open}
        onClose={toggleOpen.off}
      />
    </>
  )
}
