import React, { FC, useEffect } from 'react'
import { Box, Input } from '@revolut/ui-kit'
import { useRifm } from 'rifm'

import { TimeInputItem, TimeValue } from '../../../types'
import useScrollToRef from '../../useScrollToRef'

type Props = {
  value?: TimeInputItem['value']
  disabled: boolean
  hint?: string
  changeValue: (value: TimeValue['value']) => void
}

export const INPUT_TIME_TESTID = 'input-time-testid'
const ONLY_DIGITS_REGEXP = /[^0-9]/g

const TimeInput: FC<Props> = ({ value, disabled, changeValue, hint }) => {
  const { ref, scroll } = useScrollToRef()

  // Trim ":00+00:00" from BE response leaving only HH:MM.
  useEffect(() => {
    if (value?.value && value.value.length > 5) {
      changeValue(value.value.slice(0, 5))
    }
  }, [value?.value, changeValue])

  const rifm = useRifm({
    value: value?.value || '',
    mask: true,
    format: (val: string) => {
      val = val.replace(ONLY_DIGITS_REGEXP, '')
      // Automatically add semicolon after hours second digit
      val = val.length >= 2 ? `${val.slice(0, 2)}:${val.slice(2)}` : val
      return val.slice(0, 5)
    },
    onChange: changeValue,
  })

  return (
    <Box width="100%" mx={{ sm: 'auto', md: 0 }} ref={ref}>
      <Input
        data-testid={INPUT_TIME_TESTID}
        label={hint || 'Time'}
        placeholder="19:30"
        disabled={disabled}
        onFocus={() => scroll()}
        onClear={() => changeValue('')}
        {...rifm}
      />
    </Box>
  )
}

export default TimeInput
