import React, { FC, MouseEventHandler, useMemo } from 'react'
import { ActionButton } from '@revolut/ui-kit'

type Props = {
  disabled: boolean
  buttonTitle?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const BUTTON_SWITCH_CAMERA_TESTID = 'button-switch-camera-testid'

const SwitchCameraButton: FC<Props> = ({ onClick, disabled, buttonTitle }) => {
  const title = useMemo(() => buttonTitle || 'Switch camera', [buttonTitle])

  return (
    <ActionButton
      data-testid={BUTTON_SWITCH_CAMERA_TESTID}
      my="s-16"
      mx="s-8"
      disabled={disabled}
      useIcon="ArrowExchange"
      variant="white"
      onClick={onClick}
    >
      {title}
    </ActionButton>
  )
}

export default SwitchCameraButton
