import React, { createContext, useContext } from 'react'

type State = {
  flowId: string
  moveToTheNextView: () => void
}

export const FlowContext = createContext<State>({
  flowId: '',
  moveToTheNextView: () => {},
})

type Props = {
  flowId: string
  moveToTheNextView: () => void
  children: React.ReactNode
}

export const FlowContextProvider = ({ flowId, moveToTheNextView, children }: Props) => {
  return (
    <FlowContext.Provider
      value={{
        flowId,
        moveToTheNextView,
      }}
    >
      {children}
    </FlowContext.Provider>
  )
}

export const useFlowId = () => {
  const { flowId } = useContext(FlowContext)
  return flowId
}

export const useMoveToTheNextView = () => {
  const { moveToTheNextView } = useContext(FlowContext)
  return moveToTheNextView
}
