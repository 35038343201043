import React, { FC } from 'react'
import { Box } from '@revolut/ui-kit'
import styled from 'styled-components'

import { Img } from './styled'
import { useIsWidgetMode } from '../../../providers'

const ASSETS_URL = 'https://assets.revolut.com/'

const StyledImageBox = styled(Box)`
  width: 100%;
  text-align: center;
`
type Props = { url: string }

const Image: FC<Props> = ({ url }) => {
  const isWidgetMode = useIsWidgetMode()
  const isAssetsImage = url.startsWith(ASSETS_URL)

  // Standalone web forms support only https://assets.revolut.com/ image urls
  if (!isWidgetMode && !isAssetsImage) return null

  return (
    <StyledImageBox>
      <Img src={url} alt="" />
    </StyledImageBox>
  )
}

export default Image
