import React from 'react'
import { Group, Item, Image, TextButton } from '@revolut/ui-kit'

type Props = {
  title: string
  enterAddressManuallyLabel: string
  onEnterAddressManually: () => void
}

export const NoResultView = ({
  title,
  enterAddressManuallyLabel,
  onEnterAddressManually,
}: Props) => (
  <Group>
    <Item>
      <Item.Avatar>
        <Image src="https://assets.revolut.com/assets/3d-images/3D086@2x.png" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title color="grey-tone-50">
          {title}
          <TextButton onClick={onEnterAddressManually}>
            {enterAddressManuallyLabel}
          </TextButton>
        </Item.Title>
      </Item.Content>
    </Item>
  </Group>
)
