import { Flow } from 'types'
import { Api } from 'api'
import { useCallback } from 'react'
import { useApi } from '../providers'
import { FlowViewItemType, FlowViewType } from '../appConstants'
import { notReachable } from './helpers'

export const useValidateAndSubmitFlow = () => {
  const api = useApi()

  const validateAndSubmitFlow = useCallback(
    async (flow: Flow) => {
      const flowValidationResult = await validateFlow(flow, api)

      switch (flowValidationResult.type) {
        case 'success':
          return api.submitFlow(flowValidationResult.flow)
        case 'error':
          return flowValidationResult.flow
        default:
          return notReachable(flowValidationResult)
      }
    },
    [api],
  )

  return validateAndSubmitFlow
}

const validateFlow = async (
  flow: Flow,
  api: Api,
): Promise<{ type: 'success'; flow: Flow } | { type: 'error'; flow: Flow }> => {
  const view = flow.views?.find(({ id }) => id === flow.currentViewId)
  if (!view || view.type !== FlowViewType.Form) {
    return { type: 'success', flow }
  }

  for (const item of view.items) {
    switch (item.type) {
      case FlowViewItemType.AddressInput: {
        if (!item.value) {
          break
        }
        // eslint-disable-next-line no-await-in-loop
        const validationResult = await api.validateAddress(item.value)
        if (validationResult.type === 'success') {
          break
        }

        return {
          type: 'error',
          flow: {
            ...flow,
            views: flow.views?.map(flowViews =>
              flowViews.id !== view.id
                ? flowViews
                : {
                    ...view,
                    items: view.items.map(currentItem =>
                      currentItem.id !== item.id
                        ? currentItem
                        : { ...item, validationResult },
                    ),
                  },
            ),
          },
        }
      }
      default:
        break
    }
  }

  return { type: 'success', flow }
}
