export function getTotalFilesSize(files: FileList): number {
  let totalFilesSize = 0

  for (let i = 0; i < files.length; i++) {
    const file = files.item(i)
    totalFilesSize += file?.size ?? 0
  }

  return totalFilesSize
}

export function formatBytes(bytes: number): string {
  const marker = 1024 // Change to 1000 if required
  const decimal = 0 // Change as required
  const kiloBytes = marker // One Kilobyte is 1024 bytes
  const megaBytes = marker * kiloBytes // One MB is 1024 KB
  const gigaBytes = marker * megaBytes // One GB is 1024 MB

  // return bytes if less than a KB
  if (bytes < kiloBytes) return `${bytes} Bytes`
  // return KB if less than a MB
  if (bytes < megaBytes) return `${(bytes / kiloBytes).toFixed(decimal)} KB`
  // return MB if less than a GB
  if (bytes < gigaBytes) return `${(bytes / megaBytes).toFixed(decimal)} MB`
  // return GB if less than a TB
  return `${(bytes / gigaBytes).toFixed(decimal)} GB`
}
