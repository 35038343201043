import React from 'react'
import { StatusPopup, Button, InputProps } from '@revolut/ui-kit'

import { noop } from 'lodash'
import { notReachable } from '../../../../helpers'

import { CountryState } from '../../../../../types'
import { useCountryStates } from './useCountryStates'
import { SearchInput } from '../../SearchInput'
import ErrorPopup from '../../ErrorPopup'
import { Select } from './Select'

type Props = {
  countryCode: 'US' | 'AU'
  label: string
  placeholder: string
  errorTitle: string
  tryAgainLabel: string
  cancelButtonLabel: string
  value: CountryState['code'] | undefined
  onErrorClose: () => void
  onChange: (countryStateCode: CountryState['code']) => void
} & Pick<InputProps, 'message' | 'aria-invalid'>

export const CountryStateSelect = ({
  label,
  placeholder,
  errorTitle,
  tryAgainLabel,
  cancelButtonLabel,
  countryCode,
  value,
  onErrorClose,
  onChange,
  ...inputProps
}: Props) => {
  const countryStatesQuery = useCountryStates({ countryCode })

  switch (countryStatesQuery.status) {
    case 'loading':
      return <SearchInput label={label} pending disabled onClick={noop} />
    case 'success':
      if (value) {
        const foundCountryState = countryStatesQuery.data.find(
          ({ code }) => code === value,
        )
        if (!foundCountryState) {
          return (
            <>
              <SearchInput label={label} disabled onClick={noop} />
              <StatusPopup variant="error" open onClose={onErrorClose}>
                <StatusPopup.Title>
                  State {value} is not supported. Please provide another address
                </StatusPopup.Title>
                <StatusPopup.Actions>
                  <Button variant="secondary" onClick={onErrorClose}>
                    Cancel
                  </Button>
                </StatusPopup.Actions>
              </StatusPopup>
            </>
          )
        }
        return (
          <Select
            label={label}
            placeholder={placeholder}
            cancelButtonLabel={cancelButtonLabel}
            countryStates={countryStatesQuery.data}
            countryStateInputValue={foundCountryState.name}
            value={value}
            onChange={onChange}
            {...inputProps}
          />
        )
      }
      return (
        <Select
          label={label}
          placeholder={placeholder}
          cancelButtonLabel={cancelButtonLabel}
          countryStates={countryStatesQuery.data}
          countryStateInputValue=""
          onChange={onChange}
          value={undefined}
          {...inputProps}
        />
      )
    case 'error':
    case 'idle':
      return (
        <ErrorPopup
          errorTitle={errorTitle}
          tryAgainLabel={tryAgainLabel}
          closeLabel={cancelButtonLabel}
          onClose={onErrorClose}
          onTryAgain={() => countryStatesQuery.refetch()}
        />
      )
    /* istanbul ignore next */
    default:
      return notReachable(countryStatesQuery)
  }
}
