import { useQuery, UseQueryResult } from 'react-query'

import { Card } from '../../../types'
import { useApi } from '../../../providers'

export const useCardsQuery = (
  formId: string,
  itemId: string,
): UseQueryResult<Card[], unknown> => {
  const api = useApi()

  const cardsQuery = useQuery(['cards', formId, itemId], () =>
    api.getCards(formId, itemId),
  )

  return cardsQuery
}
