import { AddressInputValue } from 'types'

export const hasSomeAddressValueOtherThanCountry = (values: AddressInputValue): boolean =>
  !!(
    values.postcode ||
    values.streetLine1 ||
    values.streetLine2 ||
    // Since for Singapore, city is always pre-populated by default
    (values.country && values.country === 'SG' ? false : values.city) ||
    values.region
  )
