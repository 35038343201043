import { notReachable } from '../../../helpers'
import { AddressInputErrors } from './validateAddressValue'

export type AddressInputValidationErrorMessages = {
  [key in keyof AddressInputErrors]: string | null
}

export const mapErrorsToErrorMessages = (
  errors: AddressInputErrors,
  hints: {
    requiredFieldHint: string
    streetLine1MaxLengthHint: string
    streetLine2MaxLengthHint: string
    streetAddressInvalidCharactersHint: string
    cityMaxLengthHint: string
    cityInvalidCharactersHint: string
  },
): AddressInputValidationErrorMessages => ({
  streetLine1: (() => {
    if (!errors.streetLine1) {
      return null
    }
    switch (errors.streetLine1.type) {
      case 'max_string_length_is_exceeded':
        return hints.streetLine1MaxLengthHint
      case 'string_is_empty':
        return hints.requiredFieldHint
      /* istanbul ignore next */
      default:
        return notReachable(errors.streetLine1)
    }
  })(),
  streetLine2: (() => {
    if (!errors.streetLine2) {
      return null
    }
    switch (errors.streetLine2.type) {
      case 'max_string_length_is_exceeded':
        return hints.streetLine2MaxLengthHint
      /* istanbul ignore next */
      default:
        return notReachable(errors.streetLine2.type)
    }
  })(),
  city: (() => {
    if (!errors.city) {
      return null
    }
    switch (errors.city.type) {
      case 'string_is_empty':
        return hints.requiredFieldHint
      case 'max_string_length_is_exceeded':
        return hints.cityMaxLengthHint
      /* istanbul ignore next */
      default:
        return notReachable(errors.city)
    }
  })(),
  region: (() => {
    if (!errors.region) {
      return null
    }
    switch (errors.region.type) {
      case 'string_is_empty':
        return hints.requiredFieldHint
      /* istanbul ignore next */
      default:
        return notReachable(errors.region.type)
    }
  })(),
  postcode: (() => {
    if (!errors.postcode) {
      return null
    }
    switch (errors.postcode.type) {
      case 'string_is_empty':
        return hints.requiredFieldHint
      /* istanbul ignore next */
      default:
        return notReachable(errors.postcode.type)
    }
  })(),
})
