import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { HtmlString } from '../../../../types'

const Markdown = styled(ReactMarkdown)`
  p {
    margin: 0;
  }
`

type Props = {
  htmlString: HtmlString
}

const HtmlStringComponent = ({ htmlString }: Props) =>
  htmlString.type === 'HTML_STRING' ? (
    <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
      {htmlString.value}
    </Markdown>
  ) : null

export default HtmlStringComponent
