import React, { createContext, Dispatch, SetStateAction, useState } from 'react'

export type Obstacle = (callback: () => void) => void

type NavigationObstacleState = {
  navigationObstacle: Obstacle | undefined
  setNavigationObstacle: Dispatch<SetStateAction<Obstacle | undefined>>
}

export const NavigationObstacleContext = createContext<NavigationObstacleState>({
  navigationObstacle: undefined,
  setNavigationObstacle: () => {},
})

type Props = {
  children: React.ReactNode
}

export const NavigationObstacleProvider = ({ children }: Props) => {
  const [navigationObstacle, setNavigationObstacle] = useState<Obstacle>()

  return (
    <NavigationObstacleContext.Provider
      value={{ navigationObstacle, setNavigationObstacle }}
    >
      {children}
    </NavigationObstacleContext.Provider>
  )
}
