import { useQuery, UseQueryResult } from 'react-query'

import { AddressSuggestions } from '../../../../types'
import { useApi } from '../../../../providers'

export const useAddressSuggestions = ({
  countryCode,
  text,
  parent,
  limit,
}: {
  countryCode: string
  text: string
  parent: null | string
  limit: number
}): UseQueryResult<AddressSuggestions, unknown> => {
  const api = useApi()

  const addressSuggestionQuery = useQuery(
    ['addressSuggestions', countryCode, text, parent, limit],
    () => api.getAddressSuggestions({ countryCode, text, parent, limit }),
    {
      enabled: text.length > 0,
    },
  )

  return addressSuggestionQuery
}
