import React from 'react'
import { Group, Item, Text } from '@revolut/ui-kit'
import { AddressSuggestion, AddressSuggestions } from '../../../../types'
import { notReachable } from '../../../helpers'

type Props = {
  myAddressIsNotHereLabel: string
  suggestions: AddressSuggestions
  onSelect: (suggestion: AddressSuggestion) => void
  onCancel: () => void
}

export const LoadedView = ({
  suggestions,
  myAddressIsNotHereLabel,
  onCancel,
  onSelect,
}: Props) => (
  <Group>
    <Item use="button" variant="choice" onClick={onCancel}>
      <Text variant="secondary" color="primary">
        {myAddressIsNotHereLabel}
      </Text>
    </Item>
    {suggestions.map(suggestion => (
      <Item
        key={suggestion.id}
        use="button"
        variant="choice"
        onClick={() => onSelect(suggestion)}
      >
        <Item.Content>
          <ItemTitle suggestion={suggestion} />
        </Item.Content>
        {(() => {
          switch (suggestion.type) {
            case 'container':
              return <Item.Side>{suggestion.description}</Item.Side>
            case 'final':
              return null
            /* istanbul ignore next */
            default:
              return notReachable(suggestion)
          }
        })()}
      </Item>
    ))}
  </Group>
)

const ItemTitle = ({ suggestion }: { suggestion: AddressSuggestion }) => {
  switch (suggestion.type) {
    case 'container':
      return <Item.Title>{suggestion.result}</Item.Title>
    case 'final':
      return (
        <Item.Title>
          {[suggestion.result, suggestion.description].filter(Boolean).join(', ')}
        </Item.Title>
      )
    /* istanbul ignore next */
    default:
      return notReachable(suggestion)
  }
}
