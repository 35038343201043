import React from 'react'
import { Avatar, AvatarProps } from '@revolut/ui-kit'
import CountryFlag from '../CountryFlag/CountryFlag'

type Props = {
  countryCode: string
  selected: boolean
} & Pick<AvatarProps, 'size'>

export const CountryAvatar = ({ countryCode, selected, size }: Props) => {
  return (
    <CountryFlag countryCode={countryCode} size={size}>
      {selected && <Avatar.Badge useIcon="Check" />}
    </CountryFlag>
  )
}
