import React, { ChangeEvent, FC, useCallback } from 'react'
import { TextArea } from '@revolut/ui-kit'

import { Shape, StringValue, Validation } from '../../../types'
import useScrollToRef from '../../useScrollToRef'

type Props = {
  value?: StringValue
  disabled: boolean
  hint: string
  underlineText?: string
  changeValue: (value: string) => void
  validation?: Validation
  isFlowSubmitted: boolean
  shape?: Shape
}

export const TEXTAREA_TESTID = 'textarea-testid'
const MAX_ROWS = 6

const TextInput: FC<Props> = ({
  value,
  disabled,
  changeValue,
  hint,
  underlineText,
  isFlowSubmitted,
  validation,
  shape,
}) => {
  const { ref, scroll } = useScrollToRef()

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      changeValue(e.target.value)
    },
    [changeValue],
  )

  const errorMessage =
    isFlowSubmitted &&
    validation &&
    !(value && value.value.match(new RegExp(validation.pattern)))

  return (
    <TextArea
      data-testid={TEXTAREA_TESTID}
      ref={ref}
      placeholder={hint || 'Type answer here'}
      value={value?.value || ''}
      onChange={handleChange}
      disabled={disabled}
      rows={shape?.linesCount ?? 1}
      maxRows={MAX_ROWS}
      autosize={shape?.expandable ?? true}
      onFocus={() => scroll()}
      onClear={() => changeValue('')}
      invalid={errorMessage}
      errorMessage={validation && validation.message}
      description={!errorMessage && underlineText}
    />
  )
}

export default TextInput
