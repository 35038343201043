import React, { FC, Fragment, useMemo, useState, useCallback } from 'react'
import { Box, Checkbox, CheckboxGroup, Text, Input, Flex, Search } from '@revolut/ui-kit'

import { includesSubstringCaseInsensitive } from '../../helpers'
import { CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR } from '../../../appConstants'
import { MultiSelectionItem, MultiSelectionValue } from '../../../types'
import { HtmlStringComponent } from '../common'

export const CUSTOM_ANSWER_INPUT_TESTID = 'custom-answer-input-testid'

export type Props = MultiSelectionItem & {
  disabled: boolean
  changeValue: (value?: MultiSelectionValue) => void
}

const MultiSelection: FC<Props> = ({ disabled, changeValue, ...item }) => {
  const { options, customOption, noneApplyOption, searchHint, value } = item
  const { optionIds: selectedOptionIds, customOption: customOptionValue } = value ?? {}

  const showCustomInput = customOption && selectedOptionIds?.includes(customOption.id)

  const noneApplyOptionInitiallySelected =
    noneApplyOption && selectedOptionIds?.includes(noneApplyOption.id)

  const [filterValue, setFilterValue] = useState('')

  const filteredOptions = useMemo(
    () =>
      options.filter(
        option =>
          includesSubstringCaseInsensitive(option.title, filterValue) ||
          (option.description &&
            includesSubstringCaseInsensitive(option.description.value, filterValue)),
      ),
    [filterValue, options],
  )

  const changeCheckboxGroup = useCallback(
    (optionIds: string[] | null) => {
      if (optionIds === null) {
        return
      }

      if (noneApplyOption && optionIds.includes(noneApplyOption.id)) {
        // Selecting 'None of the above' should uncheck any other previously selected options, and vice versa.
        optionIds = noneApplyOptionInitiallySelected
          ? optionIds.filter(optionId => optionId !== noneApplyOption.id)
          : [noneApplyOption.id]
      }

      if (customOption && optionIds.includes(customOption.id)) {
        changeValue({
          optionIds,
          customOption: customOptionValue ?? '',
        })
        return
      }

      changeValue({ optionIds })
    },
    [
      changeValue,
      customOption,
      customOptionValue,
      noneApplyOption,
      noneApplyOptionInitiallySelected,
    ],
  )

  const changeCustomValue = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      if (!selectedOptionIds) {
        return
      }
      changeValue({
        optionIds: selectedOptionIds,
        customOption: event.currentTarget.value,
      })
    },
    [changeValue, selectedOptionIds],
  )

  return (
    <>
      {options.length > CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR && (
        <Search value={filterValue} placeholder={searchHint} onChange={setFilterValue} />
      )}
      <Box mt="s-16" radius="widget" bg="widget-background">
        <CheckboxGroup
          value={selectedOptionIds}
          disabled={disabled}
          onChange={changeCheckboxGroup}
        >
          {group => (
            <>
              {filteredOptions.map(option => (
                <Fragment key={option.id}>
                  <Box p="s-16">
                    <Checkbox
                      {...group.getInputProps({ value: option.id })}
                      data-testid={option.id}
                    >
                      <Checkbox.Label>{option.title}</Checkbox.Label>
                      {option.description?.value && (
                        <Checkbox.Description>
                          <HtmlStringComponent htmlString={option.description} />
                        </Checkbox.Description>
                      )}
                    </Checkbox>
                  </Box>
                </Fragment>
              ))}
              {customOption && (
                <Flex p="s-16" pt={showCustomInput ? 0 : 's-16'} alignItems="center">
                  <Checkbox
                    {...group.getInputProps({ value: customOption.id })}
                    data-testid={customOption.id}
                  >
                    {!showCustomInput && (
                      <Text color="foreground">{customOption?.hint || 'Other'}</Text>
                    )}
                  </Checkbox>
                  {showCustomInput && (
                    <Box width="100%">
                      <Input
                        autoFocus
                        label={customOption?.hint || 'Other'}
                        value={customOptionValue}
                        onChange={changeCustomValue}
                        disabled={disabled}
                        data-testid={CUSTOM_ANSWER_INPUT_TESTID}
                      />
                    </Box>
                  )}
                </Flex>
              )}
              {noneApplyOption && (
                <Box p="s-16">
                  <Checkbox
                    {...group.getInputProps({ value: noneApplyOption.id })}
                    data-testid={noneApplyOption.id}
                  >
                    <Checkbox.Label>
                      {noneApplyOption?.title || 'None of the above'}
                    </Checkbox.Label>
                  </Checkbox>
                </Box>
              )}
            </>
          )}
        </CheckboxGroup>
      </Box>
    </>
  )
}

export default MultiSelection
