import React, { FC, Fragment, useMemo, useState } from 'react'
import { Box, Checkbox, CheckboxGroup, Text, Input, Flex, Search } from '@revolut/ui-kit'

import { includesSubstringCaseInsensitive } from '../../helpers'
import {
  CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR,
  FlowViewItemType,
} from '../../../appConstants'
import { MultiChoiceItemLegacy, SingleChoiceTextInputItemLegacy } from '../../../types'
import { Actions } from '../../useFlowPage'

import { useMultiChoice } from './useMultiChoice'
import { HtmlStringComponent } from '../common'

export const CUSTOM_ANSWER_INPUT_TESTID = 'custom-answer-input-testid'

export type Props = {
  items: (MultiChoiceItemLegacy | SingleChoiceTextInputItemLegacy)[]
  disabled: boolean
  changeViewItemValues: Actions['changeViewItemValues']
}

const MultiChoiceLegacy: FC<Props> = ({ items = [], disabled, changeViewItemValues }) => {
  const [
    { customValue, showCustomInput, defaultValue },
    { changeCheckboxGroup, changeCustomValue },
  ] = useMultiChoice({
    items,
    changeViewItemValues,
  })

  const [filterValue, setFilterValue] = useState('')

  const filteredItems = useMemo(
    () =>
      items.filter(item =>
        item.type === FlowViewItemType.MultiChoiceLegacy &&
        typeof item.description === 'string'
          ? includesSubstringCaseInsensitive(item.description, filterValue)
          : true,
      ),
    [filterValue, items],
  )

  return (
    <>
      {items.length > CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR && (
        <Search
          value={filterValue}
          placeholder={items[0]?.searchHint || 'Search'}
          onChange={setFilterValue}
        />
      )}
      <Box mt="s-16" radius="widget" bg="widget-background">
        <CheckboxGroup
          onChange={changeCheckboxGroup}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {group =>
            filteredItems.map(item => (
              <Fragment key={item.id}>
                {item.type === FlowViewItemType.MultiChoiceLegacy && (
                  <Box p="s-16">
                    <Checkbox
                      {...group.getInputProps({ value: item.id })}
                      data-testid={item.id}
                    >
                      <Checkbox.Label title="title">{item.title}</Checkbox.Label>
                      {item.descriptionHtml?.value && (
                        <Checkbox.Description title="subtitle">
                          <HtmlStringComponent htmlString={item.descriptionHtml} />
                        </Checkbox.Description>
                      )}
                    </Checkbox>
                  </Box>
                )}
                {item.type === FlowViewItemType.SingleChoiceTextInputLegacy && (
                  <Flex p="s-16" pt={showCustomInput ? 0 : 's-16'} alignItems="center">
                    <Checkbox
                      {...group.getInputProps({ value: item.id })}
                      data-testid={item.id}
                    >
                      {!showCustomInput && (
                        <Text color="foreground">{item?.hint || 'Other'}</Text>
                      )}
                    </Checkbox>
                    {showCustomInput && (
                      <Box width="100%">
                        <Input
                          autoFocus
                          label={item?.hint || 'Other'}
                          value={customValue}
                          onChange={changeCustomValue}
                          disabled={disabled}
                          data-testid={CUSTOM_ANSWER_INPUT_TESTID}
                        />
                      </Box>
                    )}
                  </Flex>
                )}
              </Fragment>
            ))
          }
        </CheckboxGroup>
      </Box>
    </>
  )
}

export default MultiChoiceLegacy
