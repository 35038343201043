import React, { FC, MouseEventHandler, useMemo } from 'react'
import { ActionButton } from '@revolut/ui-kit'

type Props = {
  buttonTitle?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const BUTTON_RETAKE_TESTID = 'button-retake-testid'

const RetakeButton: FC<Props> = ({ buttonTitle, onClick }) => {
  const title = useMemo(() => buttonTitle || 'Re-take', [buttonTitle])

  return (
    <ActionButton
      data-testid={BUTTON_RETAKE_TESTID}
      my="s-16"
      mx="s-8"
      useIcon="Cross"
      variant="negative"
      size="sm"
      onClick={onClick}
    >
      {title}
    </ActionButton>
  )
}

export default RetakeButton
