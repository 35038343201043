import { ProgressStepState, Color, Token } from '@revolut/ui-kit'
import { TicketProgressStepLineStyle, TicketProgressStepColor } from 'types'

const TicketProgressStepLineStyleMap: Record<
  TicketProgressStepLineStyle,
  ProgressStepState
> = {
  SOLID: ProgressStepState.DONE,
  DASHED: ProgressStepState.PENDING,
}

export const getWidgetProgressionStepState = (
  style?: TicketProgressStepLineStyle,
): ProgressStepState => (style && TicketProgressStepLineStyleMap[style]) ?? 'default'

const TicketProgressStepColorMap: Record<TicketProgressStepColor, Color> = {
  HIGHLIGHTED: Token.color.accent,
  REGULAR_PRIMARY: Token.color.foreground,
  REGULAR_SECONDARY: Token.color.grey50,
  DISABLED_PRIMARY: Token.color.grey20,
  DISABLED_SECONDARY: Token.color.grey20,
  PINK: Token.color.pink,
  ORANGE: Token.color.orange,
  WHITE: Token.color.white,
  RED: Token.color.red,
  BLACK: Token.color.black,
  BLUE: Token.color.blue,
  GREEN: Token.color.green,
}

export const getWidgetProgressionStepIndicatorColor = (
  color?: TicketProgressStepColor,
): Color | undefined => color && TicketProgressStepColorMap[color]
