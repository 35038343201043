import React from 'react'

import { VStack } from '@revolut/ui-kit'
import { FlowViewItemStatic } from 'types'

import { isMobile } from 'react-device-detect'

import { ControlItemStyled } from '../Controls/common/ControlItemStyled'
import { fullWidthItemType, mapItemTypeToElement } from '../../appConstants'

import { useIsWidgetMode } from '../../providers'

type Props = {
  items: FlowViewItemStatic[]
}

const BottomPromptComponentItems = ({ items }: Props) => {
  const isWidgetMode = useIsWidgetMode()

  return (
    <VStack space="s-16">
      {items.map((item, idx) => {
        const Item = mapItemTypeToElement[item.type]

        if (!Item) {
          return null
        }

        // Static items don't have ID so we use type + index as a key
        const key = `${item.type}-${idx}`

        return (
          <ControlItemStyled
            key={key}
            fullWidth={fullWidthItemType.includes(item.type) || isMobile || isWidgetMode}
          >
            <Item {...item} />
          </ControlItemStyled>
        )
      })}
    </VStack>
  )
}

export default BottomPromptComponentItems
