import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, RadioGroup, SelectInput, Search, Subheader, VStack } from '@revolut/ui-kit'

import { includesSubstringCaseInsensitive } from '../../helpers'
import { SingleSelectionItem } from '../../../types'
import { CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR } from '../../../appConstants'
import { useMoveToTheNextView } from '../../../providers'
import { SingleSelectionOptions } from './SingleSelectionOptions'
import { OptionsWrapper } from './OptionsWrapper'
import { CustomOption } from './CustomOption'

export type Props = SingleSelectionItem & {
  disabled: boolean
  changeValue: (value?: SingleSelectionItem['value']) => void
}

export const CUSTOM_ANSWER_INPUT_TESTID = 'custom-answer-input-testid'

const SingleSelection: FC<Props> = ({ disabled, changeValue, ...item }) => {
  const {
    options,
    style,
    optionsSeparationStyle,
    assetStyle,
    groups,
    searchHint,
    value,
    customOption,
  } = item
  const { optionId: selectedOptionId, customOption: customOptionValue } = value ?? {}

  const moveToTheNextView = useMoveToTheNextView()

  const [filterValue, setFilterValue] = useState('')
  const [isMoveToTheNextViewPending, setIsMoveToTheNextViewPending] = useState(false)

  const showCustomInput = selectedOptionId === customOption?.id

  useEffect(() => {
    // Do move after the state got updated.
    if (isMoveToTheNextViewPending) {
      moveToTheNextView()
      setIsMoveToTheNextViewPending(false)
    }
  }, [isMoveToTheNextViewPending, moveToTheNextView])

  const filteredOptions = useMemo(
    () =>
      options.filter(
        option =>
          (option.title && includesSubstringCaseInsensitive(option.title, filterValue)) ||
          (option.labels &&
            option.labels.some(({ title }) =>
              includesSubstringCaseInsensitive(title, filterValue),
            )) ||
          (option.description &&
            includesSubstringCaseInsensitive(option.description.value, filterValue)),
      ),
    [filterValue, options],
  )

  const groupsWithOptions = useMemo(() => {
    if (!groups.length) {
      return undefined
    }

    return groups.map(group => ({
      ...group,
      options: group.selectionOptionIds.map(id => {
        const foundOption = options.find(option => option.id === id)
        if (!foundOption) {
          throw new Error('Unable to locate option from the group')
        }
        return foundOption
      }),
    }))
  }, [options, groups])

  const changeRadioGroup = useCallback(
    (optionId: string | null) => {
      if (optionId === null) {
        return
      }
      if (optionId === customOption?.id) {
        changeValue({
          optionId,
          customOption: '',
        })
        return
      }
      changeValue({ optionId })
    },
    [changeValue, customOption],
  )

  const changeCustomValue = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      if (!selectedOptionId) {
        return
      }
      changeValue({
        optionId: selectedOptionId,
        customOption: event.currentTarget.value,
      })
    },
    [changeValue, selectedOptionId],
  )

  const changeDropdownValue = useCallback(
    (optionId: string | null) => {
      if (optionId) {
        changeValue({ optionId })
      }
    },
    [changeValue],
  )

  if (item.style === 'DROPDOWN') {
    return (
      <SelectInput
        label={item.hint}
        options={options.map(({ id, title }) => ({
          key: id,
          label: title,
          value: id,
        }))}
        onChange={changeDropdownValue}
      />
    )
  }

  return (
    <>
      {options.length > CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR && (
        <Search
          value={filterValue}
          placeholder={searchHint || 'Search'}
          onChange={setFilterValue}
        />
      )}
      <Box mt="s-16">
        <RadioGroup
          onChange={changeRadioGroup}
          disabled={disabled}
          defaultValue={selectedOptionId}
        >
          {({ getInputProps }) => (
            <>
              {groupsWithOptions ? (
                <VStack>
                  {groupsWithOptions.map(group => (
                    <VStack key={group.id}>
                      <Subheader>{group.name}</Subheader>
                      <SingleSelectionOptions
                        options={group.options}
                        disabled={disabled}
                        style={style}
                        optionsSeparationStyle={optionsSeparationStyle}
                        assetStyle={assetStyle}
                        getInputProps={getInputProps}
                        setIsMoveToTheNextViewPending={setIsMoveToTheNextViewPending}
                      />
                    </VStack>
                  ))}
                  <CustomOption
                    customOption={customOption}
                    disabled={disabled}
                    showInput={showCustomInput}
                    showGroupsHeader={Boolean(groupsWithOptions)}
                    style={style}
                    customOptionValue={customOptionValue}
                    changeCustomValue={changeCustomValue}
                    getInputProps={getInputProps}
                  />
                </VStack>
              ) : (
                <OptionsWrapper areOptionsSpaced={optionsSeparationStyle === 'SPACED'}>
                  <SingleSelectionOptions
                    options={filteredOptions}
                    disabled={disabled}
                    style={style}
                    optionsSeparationStyle={optionsSeparationStyle}
                    assetStyle={assetStyle}
                    getInputProps={getInputProps}
                    setIsMoveToTheNextViewPending={setIsMoveToTheNextViewPending}
                  />
                  <CustomOption
                    customOption={customOption}
                    disabled={disabled}
                    showInput={showCustomInput}
                    showGroupsHeader={Boolean(groupsWithOptions)}
                    style={style}
                    customOptionValue={customOptionValue}
                    changeCustomValue={changeCustomValue}
                    getInputProps={getInputProps}
                  />
                </OptionsWrapper>
              )}
            </>
          )}
        </RadioGroup>
      </Box>
    </>
  )
}

export default SingleSelection
