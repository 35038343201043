import { ReactNode } from 'react'

import { VStack, Group } from '@revolut/ui-kit'
import React from 'react'

export const OptionsWrapper = ({
  areOptionsSpaced,
  children,
}: {
  areOptionsSpaced: boolean
  children: ReactNode
}) =>
  areOptionsSpaced ? <VStack space="s-8">{children}</VStack> : <Group>{children}</Group>
