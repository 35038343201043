import { Image, VStack, HStack, Text } from '@revolut/ui-kit'
import React from 'react'

import { SelectionOptionLabel } from 'types'
import { SelectionOptionAvatar } from './SelectionOptionAvatar'

interface Props {
  labels: SelectionOptionLabel[]
  avatarUrl?: string
}

export const SelectionOptionMultilineContent = ({ avatarUrl, labels }: Props) => {
  return (
    <HStack align="center" space="s-16">
      {avatarUrl && <SelectionOptionAvatar url={avatarUrl} />}
      <VStack space="s-8">
        {labels.map(label => (
          <HStack key={label.title} align="center" space="s-8">
            {label.icon && <Image size={16} image={label.icon.url} />}
            <Text fontWeight={500}>{label.title}</Text>
          </HStack>
        ))}
      </VStack>
    </HStack>
  )
}
