import React from 'react'
import { StatusPopup, Button } from '@revolut/ui-kit'

const ErrorPopup = ({
  errorTitle,
  tryAgainLabel,
  closeLabel,
  onTryAgain,
  onClose,
}: {
  errorTitle: string
  tryAgainLabel: string
  closeLabel: string
  onTryAgain: () => void
  onClose: () => void
}) => {
  return (
    <StatusPopup variant="error" open onClose={onClose}>
      <StatusPopup.Title>{errorTitle}</StatusPopup.Title>
      <StatusPopup.Actions>
        <Button elevated onClick={onTryAgain}>
          {tryAgainLabel}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          {closeLabel}
        </Button>
      </StatusPopup.Actions>
    </StatusPopup>
  )
}

export default ErrorPopup
