import { StatusWidget, WidgetProps } from '@revolut/ui-kit'
import React from 'react'

type Props = {
  onRetry?(): void
} & WidgetProps

const ItemError = ({ onRetry, ...widgetProps }: Props) => {
  return (
    <StatusWidget {...widgetProps}>
      <StatusWidget.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
        }}
      />
      {/* TODO: Translate */}
      <StatusWidget.Title>Something went wrong</StatusWidget.Title>
      {onRetry && <StatusWidget.Action onClick={onRetry}>Retry</StatusWidget.Action>}
    </StatusWidget>
  )
}

export default ItemError
