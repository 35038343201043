import { useQuery, UseQueryResult } from 'react-query'

import { AddressInputValue } from '../../../../types'
import { useApi } from '../../../../providers'

export const useAddressValuesByPostcode = ({
  countryCode,
  postcode,
}: {
  countryCode: string
  postcode: string
}): UseQueryResult<Array<AddressInputValue>, unknown> => {
  const api = useApi()

  const addressSuggestionQuery = useQuery(
    ['addressSuggestions', countryCode, postcode],
    () => api.getAddressesByPostcode(countryCode, postcode),
    {
      enabled: postcode.length > 0,
    },
  )

  return addressSuggestionQuery
}
