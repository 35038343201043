import { useQuery, UseQueryResult } from 'react-query'

import { AddressCountryConfig } from '../../../types'
import { useApi } from '../../../providers'

export const useCountriesConfig = (): UseQueryResult<AddressCountryConfig, unknown> => {
  const api = useApi()

  const countriesConfigQuery = useQuery(['countriesConfig'], () =>
    api.getCountriesConfig(),
  )

  return countriesConfigQuery
}
