import React from 'react'

import { Item, ItemProps, Checkbox, CheckboxGroupState } from '@revolut/ui-kit'
import { Transaction } from 'types'
import { TransactionAvatar } from './TransactionAvatar'
import { formatDate, getCurrencyLabel } from './utils'

type Props<T> = {
  transaction: Transaction
  checkboxGroupInputProps?: ReturnType<CheckboxGroupState<T>['getInputProps']>
  openTransactionDetails?: (transactionId: string) => void
} & ItemProps

export function TransactionItem<T>({
  transaction,
  checkboxGroupInputProps,
  openTransactionDetails,
  ...itemProps
}: Props<T>) {
  const { description, createdDate, amount } = transaction

  return (
    <Item
      {...itemProps}
      onClick={() => openTransactionDetails?.(transaction.id)}
      data-testid={transaction.id}
      {...(openTransactionDetails && { use: 'button' })}
    >
      {checkboxGroupInputProps && (
        <Item.Prefix>
          <Checkbox {...checkboxGroupInputProps} />
        </Item.Prefix>
      )}
      <Item.Avatar>
        <TransactionAvatar transaction={transaction} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{description}</Item.Title>
        <Item.Description>{formatDate(createdDate)}</Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>{getCurrencyLabel(amount)}</Item.Value>
      </Item.Side>
    </Item>
  )
}
