import { AddressInputValue } from 'types'

const MAX_LENGTH = 50

type EmptyStringError = { type: 'string_is_empty' }

type MaxStringLengthError = {
  type: 'max_string_length_is_exceeded'
  value: string
  max: number
}

type StreetLine1Error = EmptyStringError | MaxStringLengthError
type StreetLine2Error = MaxStringLengthError
type CityError = EmptyStringError | MaxStringLengthError
type RegionError = EmptyStringError
type PostcodeError = EmptyStringError

export type AddressInputErrors = {
  postcode?: PostcodeError | null
  streetLine1?: StreetLine1Error | null
  streetLine2?: StreetLine2Error | null
  city?: CityError | null
  region?: RegionError | null
}

export const validateAddressValue = (value: AddressInputValue): AddressInputErrors => ({
  postcode: checkPostcode(value.postcode),
  streetLine1: checkStreetLine1(value.streetLine1),
  streetLine2: checkStreetLine2(value.streetLine2),
  city: countryHasCities(value.country) ? checkCity(value.city) : null,
  region: countryHasRegions(value.country) ? checkRegion(value.region) : null,
})

const checkStreetLine1 = (streetLine1: string | undefined): StreetLine1Error | null => {
  if (!streetLine1 || !streetLine1.length) {
    return { type: 'string_is_empty' }
  }
  if (streetLine1.length > MAX_LENGTH) {
    return {
      type: 'max_string_length_is_exceeded',
      value: streetLine1,
      max: MAX_LENGTH,
    }
  }

  return null
}

const checkStreetLine2 = (streetLine2: string | undefined): StreetLine2Error | null => {
  if (streetLine2 && streetLine2.length > MAX_LENGTH) {
    return {
      type: 'max_string_length_is_exceeded',
      value: streetLine2,
      max: MAX_LENGTH,
    }
  }

  return null
}

const checkPostcode = (postcode: string | undefined): PostcodeError | null => {
  if (!postcode || !postcode.length) {
    return { type: 'string_is_empty' }
  }

  return null
}

const checkRegion = (region: string | undefined): RegionError | null => {
  if (!region || !region.length) {
    return { type: 'string_is_empty' }
  }

  return null
}

const checkCity = (city: string | undefined): CityError | null => {
  if (!city || !city.length) {
    return { type: 'string_is_empty' }
  }

  if (city.length > MAX_LENGTH) {
    return {
      type: 'max_string_length_is_exceeded',
      value: city,
      max: MAX_LENGTH,
    }
  }

  return null
}

const countryHasCities = (countryCode: string) => {
  switch (countryCode) {
    case 'SG':
      return false
    default:
      return true
  }
}

const countryHasRegions = (countryCode: string) => {
  switch (countryCode) {
    case 'CA':
    case 'MX':
    case 'AU':
    case 'US':
      return true
    default:
      return false
  }
}
