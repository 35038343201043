import { FC } from 'react'
import { HStack, Icon, TextButton, Text } from '@revolut/ui-kit'
import React from 'react'

type Props = {
  label: string
  onClick(): void
}

export const SecondaryDescriptionButton: FC<Props> = ({ label, onClick }) => {
  return (
    <TextButton onClick={onClick}>
      <HStack align="center" space="s-4">
        <Icon name="InfoOutline" color="accent" />
        <Text>{label}</Text>
      </HStack>
    </TextButton>
  )
}
