import React, { ReactElement } from 'react'
import { FC } from 'react'
import { Avatar } from '@revolut/ui-kit'

import { getTransactionIconUrl } from './utils'
import { Transaction } from '../../../../../types'

type Props = {
  transaction: Transaction
}

export const TRANSACTION_AVATAR_TEST_ID = 'transaction-avatar-test-id'

export const TransactionAvatar: FC<Props> = ({ transaction }: Props) => {
  const { id, avatar, icon: iconCode, iconUrl } = transaction
  const image = getTransactionIconUrl(iconUrl, iconCode)

  if (avatar) {
    return React.cloneElement(avatar as ReactElement, {
      'data-testid': TRANSACTION_AVATAR_TEST_ID,
    })
  }

  return (
    <Avatar
      uuid={id}
      size={40}
      image={image}
      variant="brand"
      data-testid={TRANSACTION_AVATAR_TEST_ID}
    />
  )
}
