import { Input as UIKitInput, Icon } from '@revolut/ui-kit'
import React from 'react'
import { CountryAvatar } from './CountryAvatar'
import { Country } from '../../../../types'

export const Input = ({
  label,
  countries,
  countryCode,
  isOpened,
  disabled,
  pending,
  onClick,
}: {
  label: string
  countries: Country[]
  countryCode: string | null
  isOpened: boolean
  disabled: boolean
  pending: boolean
  onClick: () => void
}) => {
  const countryName =
    countries.find(({ code }) => code === countryCode)?.description || ''
  return (
    <UIKitInput
      label={label}
      type="button"
      value={countryName}
      renderPrefix={() =>
        countryCode ? (
          <CountryAvatar countryCode={countryCode} selected={false} size={24} />
        ) : null
      }
      renderAction={() => (
        <Icon size={24} color="grey-50" name={isOpened ? 'ChevronUp' : 'ChevronDown'} />
      )}
      style={{ cursor: 'pointer' }}
      disabled={disabled}
      pending={pending}
      onClick={onClick}
    />
  )
}
