import React from 'react'

import { Group, DetailsCell, LineClamp } from '@revolut/ui-kit'

import { TableItem } from '../../../types'

const LINE_CLAMP_MAX = 3

type Props = TableItem

const SectionDetails = ({ items }: Props) => (
  <Group>
    {items.map(({ id, title, value }) => (
      <DetailsCell key={id}>
        <DetailsCell.Title>
          <LineClamp max={LINE_CLAMP_MAX}>{title}</LineClamp>
        </DetailsCell.Title>
        <DetailsCell.Content>
          <LineClamp max={LINE_CLAMP_MAX}>{value}</LineClamp>
        </DetailsCell.Content>
      </DetailsCell>
    ))}
  </Group>
)

export default SectionDetails
