import {
  Subheader,
  Cell,
  Radio,
  Box,
  Input,
  Text,
  RadioGroupState,
} from '@revolut/ui-kit'
import React from 'react'

import { SingleSelectionItem } from 'types'
import { CUSTOM_ANSWER_INPUT_TESTID } from '.'

interface Props {
  customOption: SingleSelectionItem['customOption']
  showInput: boolean
  disabled: boolean
  showGroupsHeader: boolean
  customOptionValue: string | undefined
  style: SingleSelectionItem['style']
  changeCustomValue: (event: React.SyntheticEvent<HTMLInputElement>) => void
  getInputProps: RadioGroupState<string>['getInputProps']
}

export const CustomOption = ({
  customOption,
  showInput,
  disabled,
  showGroupsHeader,
  customOptionValue,
  style,
  changeCustomValue,
  getInputProps,
}: Props) => {
  if (!customOption) return null
  if (style !== 'RADIO_BUTTON') return null

  return (
    <>
      {showGroupsHeader && <Subheader>Custom option</Subheader>}
      <Cell p="s-16" py={showInput ? 's-4' : 's-16'} alignItems="center">
        <Radio
          {...getInputProps({ value: customOption.id })}
          data-testid={customOption.id}
        >
          {!showInput && <Text color="foreground">{customOption.hint || 'Other'}</Text>}
        </Radio>
        {showInput && (
          <Box width="100%">
            <Input
              autoFocus
              label={customOption.hint || 'Other'}
              value={customOptionValue}
              onChange={changeCustomValue}
              disabled={disabled}
              data-testid={CUSTOM_ANSWER_INPUT_TESTID}
            />
          </Box>
        )}
      </Cell>
    </>
  )
}
